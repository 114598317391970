import tokenChart from '../assets/images/home/token-chart.png'
import digital1 from '../assets/images/home/digital-1.png'
import digital2 from '../assets/images/home/digital-2.png'
import digital3 from '../assets/images/home/digital-3.png'
import copyIcon from '../assets/images/home/copy_icon.png'
import { useTranslation } from 'react-i18next'

const AboutToken = () => {
    const { t } = useTranslation();

    return (
        <section className="token__section">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-5">
                        <div className="token__image__bx">
                            <img className="w-100" src={tokenChart} alt="Token Chart" />
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="token__table__container">
                            <h3 className="mb-3">{t("aboutToken.title")}</h3>
                            <div className="token__text__name">
                                <p>{t("aboutToken.tokenName")} :</p>
                                <h6>AANAND</h6>
                            </div>
                            <div className="token__text__name">
                                <p>{t("aboutToken.symbol")} :</p>
                                <h6>AND</h6>
                            </div>
                            <div className="token__text__name">
                                <p>{t("aboutToken.protocol")} :</p>
                                <h6>ERC20</h6>
                            </div>
                            <div className="token__text__name">
                                <p>{t("aboutToken.totalSupply")} :</p>
                                <h6>1,000,000,000 AND</h6>
                            </div>
                            <div className="token__text__name">
                                <p>{t("aboutToken.type")} :</p>
                                <h6>{t("aboutToken.utilityToken")}</h6>
                            </div>
                            <div className="token__copy__bx">
                                <p>{t("aboutToken.contract")}: 0x1b4A74e55b9C...3991E08BFb42f829
                                    <button className="btn">
                                        <img src={copyIcon} alt="copy" />
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutToken