import { useEffect, useState } from "react";
import Slider from "react-slick";
import { addCommasToNumber, getNativeBalance, getTokenBalance, isFloat } from "../../utils/helper";
import { RPCList, TokenAddressList } from "../../utils/networkList";
import { isAddress } from "ethers";
import SkeletonLoader from "../Skeleton";
import { TokenIcon } from "../../utils/networkList"
import { getUsdValues } from "../../services/cryptoCompare";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToScroll: 1
};

const Portfolio = ({ walletAddress }) => {
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [totalAssets, setTotalAssets] = useState(0);
    const [tokenBalance, setTokenBalance] = useState([
        { network: 'Polygon', token: 'AND', balanceValue: 'AND', balance: null, usdValue: null },
        { network: 'Polygon', token: 'MATIC', balanceValue: 'MATIC', balance: null, usdValue: null },
        { network: 'Polygon', token: 'USDT', balanceValue: 'MUSDT', balance: null, usdValue: null },
        { network: 'Polygon', token: 'USDC', balanceValue: 'MUSDC', balance: null, usdValue: null },
        { network: 'Avalanche', token: 'AVAX', balanceValue: 'AVAX', balance: null, usdValue: null },
        { network: 'Avalanche', token: 'USDT', balanceValue: 'AUSDT', balance: null, usdValue: null },
        { network: 'Avalanche', token: 'USDC', balanceValue: 'AUSDC', balance: null, usdValue: null },
        { network: 'Binance', token: 'BNB', balanceValue: 'BNB', balance: null, usdValue: null },
        { network: 'Binance', token: 'USDT', balanceValue: 'BUSDT', balance: null, usdValue: null },
        { network: 'Binance', token: 'USDC', balanceValue: 'BUSDC', balance: null, usdValue: null }
    ]);

    const getNativeAmount = async () => {
        setLoading(true);
        const [
            MATIC, BNB, AVAX,
            AND, MUSDT, MUSDC,
            BUSDT, BUSDC,
            AUSDT, AUSDC,
            usdRates
        ] = await Promise.all([
            getNativeBalance(walletAddress, RPCList?.MATIC),
            getNativeBalance(walletAddress, RPCList?.BSC),
            getNativeBalance(walletAddress, RPCList?.AVAX),
            getTokenBalance(TokenAddressList?.MATIC?.AND, walletAddress, RPCList?.MATIC),
            getTokenBalance(TokenAddressList?.MATIC?.USDT, walletAddress, RPCList?.MATIC),
            getTokenBalance(TokenAddressList?.MATIC?.USDC, walletAddress, RPCList?.MATIC),
            getTokenBalance(TokenAddressList?.BSC?.USDT, walletAddress, RPCList?.BSC),
            getTokenBalance(TokenAddressList?.BSC?.USDC, walletAddress, RPCList?.BSC),
            getTokenBalance(TokenAddressList?.AVAX?.USDT, walletAddress, RPCList?.AVAX),
            getTokenBalance(TokenAddressList?.AVAX?.USDC, walletAddress, RPCList?.AVAX),
            getUsdValues()
        ]);

        const balaceArr = { MATIC: MATIC, BNB: BNB, AVAX: AVAX, AND: AND, MUSDT: MUSDT, MUSDC: MUSDC, BUSDT: BUSDT, BUSDC: BUSDC, AUSDT: AUSDT, AUSDC: AUSDC };

        let assetsValue = 0;
        const updatedBalances = tokenBalance.map(token => {
            const balance = balaceArr[token?.balanceValue]; // Get USD rate for the token
            const usdValue = balaceArr[token?.balanceValue] * usdRates[token.token]?.USD || 0;
            assetsValue += usdValue
            return { ...token, balance, usdValue };
        });
        setTotalAssets(assetsValue)
        setTokenBalance(updatedBalances);
        setLoading(false);
    };

    useEffect(() => {
        if (isAddress(walletAddress)) {
            getNativeAmount();
        } else {
            const resetBalances = tokenBalance.map(token => ({
                ...token,
                balance: 0.0,
                usdValue: 0.0
            }));
            setTokenBalance(resetBalances);
        }
    }, [walletAddress]);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked); // Update the state with the checkbox value
    };

    return (
        <div className="portfolio-section">
            <div className="card-banr">
                <Slider {...settings}>
                    <div className="slide-portfolio-nw">
                        <div className="inner-flex-slide">
                            <p>Total AND</p>
                            {loading ? <div style={{ maxWidth: 150 }}><SkeletonLoader height={20} width={40} /></div> :
                                <h3>AND {tokenBalance?.[0]?.balance
                                    ? `${addCommasToNumber(parseFloat(tokenBalance?.[0]?.balance).toFixed(2))}`
                                    : "0.00"}
                                </h3>}
                            {loading ? <div style={{ maxWidth: 150, marginTop: 15 }}><SkeletonLoader height={20} width={40} /></div> :
                                <h6>${tokenBalance?.[0]?.usdValue
                                    ? `${addCommasToNumber(parseFloat(tokenBalance?.[0]?.usdValue).toFixed(2))}`
                                    : "0.00"}</h6>}
                        </div>

                    </div>
                    <div className="slide-portfolio-nw secound-slider">
                        <div className="inner-flex-slide">
                            <p>Total Assets</p>
                            {loading ? <div style={{ maxWidth: 150 }}><SkeletonLoader height={20} width={40} /></div> : <h3>${addCommasToNumber(parseFloat(totalAssets).toFixed(2))}</h3>}
                        </div>
                    </div>
                </Slider>
            </div>

            <div className="listing-main-div">
                <div className="togglebutton">
                    <div className="check-box">
                        <input
                            type="checkbox"
                            checked={isChecked} // Bind state to the checkbox
                            onChange={handleCheckboxChange} // Handle the change event
                        />
                    </div>
                </div>
                <div className="portfolio-list-main-dv">
                    {tokenBalance?.map((_token, _index) => {
                        const _balance = _token?.balance
                            ? addCommasToNumber(isFloat(parseFloat(_token.balance))
                                ? parseFloat(_token.balance).toFixed(4)
                                : _token.balance)
                            : 0;
                        const _usdValue = _token?.usdValue
                            ? `$${addCommasToNumber(parseFloat(_token.usdValue).toFixed(2))}`
                            : "$0.00";

                        if (!loading && _balance <= 0 && ['USDT', 'USDC'].includes(_token?.token) && isChecked) {
                            return null;
                        }

                        return (
                            <div key={_index} className="listing-portfolio">
                                <div className="listing-portfolio-left">
                                    <div className="walleticon-circle">
                                        <img src={TokenIcon[_token.token]} alt={"token-icon"} />
                                        <img className="networkicon-port" src={TokenIcon[_token.network]} alt="network-icon" />
                                    </div>
                                    <div className="listing-portfolio-icons">
                                        <h5>{_token.token}<span>{_token.network}</span></h5>
                                        <p>{_usdValue}</p>
                                    </div>
                                </div>
                                <div className="listing-portfolio-right">
                                    {loading ? <SkeletonLoader count={1} /> : _balance}
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
        </div>
    );
};

export default Portfolio