import { useEffect, useState } from "react";

const useLocalStorage = (key, initialData) => {
    const [localData, setLocalData] = useState(() => {
        const storedData = localStorage.getItem(key);
        return storedData ? JSON.parse(storedData) : initialData;
    });

    useEffect(() => {
        // Ensure localStorage is synced when key or initialData changes
        if (!localStorage.getItem(key)) {
            localStorage.setItem(key, JSON.stringify(initialData));
        }
    }, [key, initialData]);

    const updateLocalData = (key, newData) => {

        if (typeof newData === "function") {
            setLocalData((prevData) => {
                const updatedData = newData(prevData);
                localStorage.setItem(key, JSON.stringify(updatedData));
                return updatedData;
            });
        } else {
            localStorage.setItem(key, JSON.stringify(newData));
            setLocalData(newData);
        }
    }

    return { localData, updateLocalData };
};

export default useLocalStorage;
