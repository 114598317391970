import { TabPanel } from "@headlessui/react"
import Spinner from "../Spinner"
import { ANAND_PRICE } from "../../assets/constant"
import { TokenIcon } from "../../utils/networkList"
import IconMatic from '../../assets/images/matic-icon.svg'
import { handleNumberValidation } from "../../utils/helper"

const SendANDToken = ({ walletAddress, transactionInfo, isSendDisable, handleSendAmount, handleReceiverAddress, handleSend }) => {
    return (
      <TabPanel>
        <div className='tab-content-dv'>
          <div className='input-form'>
            <label>Sender Address</label>
            <input maxLength={70} disabled value={walletAddress} placeholder='Sender Address' />
          </div>
          <div className='input-form'>
            <label>Receiver Address</label>
            <input maxLength={70} value={transactionInfo?.receiveAddress} onChange={handleReceiverAddress} placeholder='Enter Receiver Address' />
          </div>
          <div className='input-form small-icon'>
            <label>AND Amount</label>
            <div className='crypto-input'>
              <div className='listing-portfolio-left'>
                <div className='walleticon-circle'>
                  <img src={TokenIcon?.AND} alt="and-icon" />
                  <img className='networkicon-port' src={IconMatic} alt="polygon-network" />
                </div>
                <div className='listing-portfolio-icons'>
                  <h5>AND</h5>
                </div>
              </div>
              <input onKeyDown={handleNumberValidation} maxLength={10} value={transactionInfo?.sendAmount} onChange={handleSendAmount} placeholder='0.0' />
            </div>
            {parseFloat(transactionInfo?.sendAmount) > 0 ? <p>${transactionInfo?.sendAmount * ANAND_PRICE}</p> : <p style={{ minHeight: 23 }}></p>}
          </div>
          <button disabled={isSendDisable || transactionInfo?.txInProcess} onClick={handleSend} className='btn-send'><Spinner loading={transactionInfo?.txInProcess} /> {transactionInfo?.txInProcess ? 'Sending...' : 'Send'}</button>
        </div>
      </TabPanel>
    )
  }

  export default SendANDToken