import React from "react";
import ChevLeft from "../assets/images/icons/chevLeft";
import ChevRight from "../assets/images/icons/chevRight";

const Pagination = ({
  metaData,
  limit,
  filter,
  handlePageChange,
  searchKey,
}) => {
  return (
    <div className="trans-pagination">
      <ul>
        <li
          className={`btn px-2 ${metaData?.currentPage === metaData?.firstPage ? 'disabled cursor-disabled' : ''}`}
          onClick={() => {
            if (metaData?.firstPage) {
              let nextPage = metaData?.firstPage;

              let _address = "";
              if (searchKey) {
                _address = searchKey ? searchKey : "";
              }

              handlePageChange(nextPage, limit, _address, filter);
            }
          }}
        >
          First
        </li>
        <li
          className={`btn btn-left ${metaData?.currentPage === metaData?.firstPage ? 'disabled cursor-disabled' : ''}`}
          onClick={() => {
            if (metaData?.currentPage > 1) {
              let nextPage = metaData.currentPage - 1;

              let _address = "";
              if (searchKey) {
                _address = searchKey ? searchKey : "";
              }

              handlePageChange(nextPage, limit, _address, filter);
            }
          }}
        >
          <ChevLeft/>
        </li>
        <li className="btn btn-active">
          {metaData ? metaData?.currentPage : 0}
        </li>
        <li className="remaining-page">
          of {metaData ? parseInt(metaData?.lastPage)?.toLocaleString() : 0}
        </li>
        <li
          className={`btn btn-left ${metaData?.currentPage === metaData?.lastPage ? 'disabled cursor-disabled' : ''}`}
          onClick={() => {
            if (metaData?.currentPage < metaData?.lastPage) {
              let nextPage = metaData.currentPage + 1;

              let _address = "";
              if (searchKey) {
                _address = searchKey ? searchKey : "";
              }
              handlePageChange(nextPage, limit, _address, filter);
            }
          }}
        >
          <ChevRight/>
        </li>
        <li
          className={`btn px-2 ${metaData?.currentPage === metaData?.lastPage ? 'disabled cursor-disabled' : ''}`}
          onClick={() => {
            if (metaData?.lastPage) {
              let nextPage = metaData?.lastPage;

              let _address = "";
              if (searchKey) {
                _address = searchKey ? searchKey : "";
              }

              handlePageChange(nextPage, limit, _address, filter);
            }
          }}
        >
          Last
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
