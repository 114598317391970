import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let notification = "";

const setting = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    closeButton: false,
    toastId: "success"
}

const Notify = (notificationText, type) => {
    if (notificationText === notification) {
        return false;
    }

    notification = notificationText;

    setTimeout(() => {
        notification = "";
    }, 5000);

    if (type === "success") {
        return toast.success(notificationText, setting);
    }

    if (type === "error" || type === "failure") {
        return toast.error(notificationText, setting);
    }


    if (type === "info") {
        return toast.info(notificationText, setting);
    }
}

export default Notify