import { ethers, formatUnits, parseUnits } from "ethers";
import { ChainIdList, ChainName, ChainSymbol, ChainTokenSymbol, ExplorerList, RPCList } from "./networkList";

function getFormattedDateTime(_date) {
    const now = new Date(_date);

    // Extract date components
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = now.getFullYear();

    // Extract time components
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    // Combine into the desired format
    return `${day}-${month}-${year}-${hours}:${minutes}:${seconds}`;
}

function emphasisAddress(str, _length = 5) {
    if (str?.length >= 8) {
        const firstFour = str.substring(0, _length);
        const lastFour = str.substring(str.length - _length);
        return `${firstFour}...${lastFour}`;
    } else {
        return str;
    }
}


function addCommasToNumber(number) {
    if (number) {
        // Split the number by decimal point
        var parts = number?.toString()?.split(".");
        // Add commas to the whole number part
        parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Join the parts back together
        return parts?.join(".");
    }
}

function isFloat(n) {
    return !isNaN(n) && parseFloat(n) === n && n % 1 !== 0;
}

const copyTextToClipboard = (text) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            return true;
        })
        .catch((error) => {
            console.error("Unable to copy text to clipboard:", error);
            return false;
        });
};

async function requestMetamaskNetworkChange(_networkSymbol) {
    try {
        if (_networkSymbol === "ETH") {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [
                    {
                        chainId: "0x1", // Ethereum Mainnet
                    },
                ],
            });
        } else {

            let chainId = ChainIdList[_networkSymbol];
            chainId = numberToHex(chainId)
            // First try to switch to the network if it exists
            try {
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                        {
                            chainId: chainId,
                        },
                    ],
                });
            } catch (switchError) {
                // If the network is not available (error code 4902), add the network
                if (switchError.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [{
                                chainId: ChainIdList[_networkSymbol],
                                rpcUrls: [RPCList[_networkSymbol]],
                                chainName: ChainName[_networkSymbol],
                                nativeCurrency: {
                                    name: ChainSymbol(_networkSymbol),
                                    symbol: ChainTokenSymbol(_networkSymbol),
                                    decimals: 18,
                                },
                                blockExplorerUrls: [ExplorerList[_networkSymbol]],
                            }]
                        });
                    } catch (addError) {
                        // errorHandler(addError?.message)
                        console.error("Failed to add network:", addError.message);
                        return false;
                    }
                } else {
                    // errorHandler(switchError?.message)
                    console.error("Failed to switch network:", switchError.message);
                    return false;
                }
            }
        }
        return true;
    } catch (e) {
        console.error("Error changing network:", e.message);
        return false;
    }
}

// Function to get native coin balance
const getNativeBalance = async (address, _rpc) => {
    const provider = new ethers.JsonRpcProvider(_rpc);
    const balance = await provider.getBalance(address);
    return ethers.formatEther(balance)
};

// Function to get token balance
const getTokenBalance = async (tokenAddress, walletAddress, _rpc) => {
    // ERC20 contract ABI and address
    const ERC20_ABI = [
        "function balanceOf(address owner) view returns (uint256)",
        "function decimals() view returns (uint8)"
    ];

    const provider = new ethers.JsonRpcProvider(_rpc);

    const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
    const tokenDecimals = await tokenContract.decimals()
    const balance = await tokenContract.balanceOf(walletAddress);

    return ethers.formatUnits(balance, tokenDecimals)
};

const handleNumberValidation = (evt) => {
    let theEvent = evt || window.event;
    const allowedKeys = [
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Backspace",
        "Tab",
        ".",
    ];
    const regex = /^\d+(\.\d+)?$/;

    if (!regex.test(theEvent.key) && !allowedKeys.includes(theEvent.key)) {
        theEvent.preventDefault();
    }

    if (theEvent.key === ".") {
        if (theEvent.target.value.includes(".")) {
            theEvent.preventDefault(); // Prevent entering another dot
        }
    }
};

function numberToHex(input) {
    if (typeof input !== 'number' && typeof input !== 'string') {
        throw new Error("Input must be a valid number or a string representing a number.");
    }

    const number = typeof input === 'string' ? parseFloat(input) : input;

    if (isNaN(number)) {
        throw new Error("Input must be a valid number.");
    }

    const hexNumber = Math.floor(number).toString(16).toUpperCase(); // Ensure integer for hex conversion

    return '0x' + hexNumber
}

// Hex to Decimal
function hexToDecimal(hex) {
    if (typeof hex !== 'string') {
        throw new Error("Input must be a valid hexadecimal string.");
    }

    return parseInt(hex, 16).toString();
}

const metamaskErrorHandler = (error) => {
    console.log("error?.message", error);
    const errorCode = error?.code;

    if (error?.message?.includes("Token Reserve is not enough, please contact support")) {
        return "Token Reserve is not enough, please contact support"
    }

    if (error?.message?.includes("Transaction started at")) {
        return false;
    }

    if (errorCode === -32002) {
        return "Already processing one transaction";
    }
    if (errorCode === -32603) {
        return "Insufficient funds for transaction gas fee";
    }

    if (error?.message?.includes("insufficient funds for gas")) {
        return "Insufficient funds for transaction gas fee";
    }

    if (error?.message?.includes("User rejected the request.")) {
        return "User rejected the request.";
    }

    if (
        error?.message?.includes(
            "insufficient funds for intrinsic transaction cost"
        )
    ) {
        return "Insufficient funds for transaction gas fee";
    }

    if (
        error?.message?.includes("Swap destination amount should be greater than 0")
    ) {
        return "Swap destination amount should be greater than 0";
    }

    if (error?.message?.includes("insufficient funds for gas * price + value")) {
        return "Insufficient funds for transaction gas fee";
    }

    if (error?.message?.includes("User denied transaction signature")) {
        return "Transaction cancelled in Metamask by you";
    }

    // Check for insufficient balance for source amount
    if (error?.message?.includes("insufficient balance for the source amount")) {
        return "Insufficient balance to complete the transaction";
    }

    return "Something went wrong while processing the transaction";
};

function truncateNumber(number, decimalPlaces = 4) {
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.floor(number * factor) / factor;

    return Number(truncatedValue.toString());
}

function decimalizeNumber(number, decimals = 18) {
    try {
        // Validate number is a valid number and is not null/undefined
        if (number === null || number === undefined || isNaN(number)) {
            throw new Error('Invalid number input');
        }

        // Ensure decimals is a valid positive integer
        if (typeof decimals !== 'number' || !Number.isInteger(decimals) || decimals < 0) {
            throw new Error('Invalid decimals input. It must be a non-negative integer.');
        }

        // Parse number to a float and ensure correct decimal precision
        const parsedNumber = parseFloat(number);

        if (isNaN(parsedNumber)) {
            throw new Error('Failed to parse the number.');
        }

        // Convert the number to the required decimal precision and parse it with ethers' parseUnits
        const fixedNumber = parsedNumber.toFixed(decimals);

        return parseUnits(fixedNumber, decimals); // ethers.js method that returns the decimalized number
    }
    catch (error) {
        // errorHandler(error?.message)
    }
}

function normalizeNumber(number, decimals = 18) {
    try {

        // Validate number is a valid BigNumber/string and is not null/undefined
        if (number === null || number === undefined) {
            throw new Error('Invalid number input');
        }

        // Ensure decimals is a valid positive integer
        if (typeof decimals !== 'number' || !Number.isInteger(decimals) || decimals < 0) {
            throw new Error('Invalid decimals input. It must be a non-negative integer.');
        }

        try {
            // Convert the number from its decimalized format to a human-readable format
            const normalizedNumber = formatUnits(number, decimals);

            // Return as a float number for easier use
            return normalizedNumber;
        } catch (error) {
            throw new Error('Failed to normalize the number: ' + error.message);
        }

    } catch (error) {
        throw new Error('Failed to normalize the number: ' + error.message);
    }
}

export {
    getFormattedDateTime,
    emphasisAddress,
    addCommasToNumber,
    copyTextToClipboard,
    requestMetamaskNetworkChange,
    getNativeBalance,
    getTokenBalance,
    isFloat,
    handleNumberValidation,
    numberToHex,
    hexToDecimal,
    metamaskErrorHandler,
    truncateNumber,
    normalizeNumber,
    decimalizeNumber
}