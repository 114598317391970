import React, { useEffect, useState } from 'react'
import commingSoonTag from '../assets/images/home/coming-soon-tag.png'
import productBanner from '../assets/images/home/prod-banner-img.png'

import chromeStore from '../assets/images/home/chrome__icon.svg'
import cryptoWallet from '../assets/images/home/crypto-wallet.png'

import product1 from '../assets/images/home/prod-1.png'
import product2 from '../assets/images/home/prod-2.png'
import product3 from '../assets/images/home/prod-3.png'
import product4 from '../assets/images/home/prod-4.png'
import googleChrome from '../assets/images/home/google-chrome.png'

import bnbIcon from '../assets/images/home/bnb-logo.png'
import avalancheIcon from '../assets/images/home/avalanche-c-chain-logo.png'
import arbitrumIcon from '../assets/images/home/arbitrum-logo.png'
import ethereumIcon from '../assets/images/home/ethereum-logo.png'
import optimisticIcon from '../assets/images/home/optimistic-ethereum-logo.png'

import '../assets/styles/home.css'
import NewHeader from '../components/NewHeader';
import NewFooter from '../components/NewFooter'

const Product = () => {
    return (
        <div>
            <NewHeader />

            <section className="product__banner__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="hero__banner__head">
                                <h1>Explore Web3 with the AND Wallet – Your Gateway to True Crypto Freedom</h1>
                                <p>The AND Wallet Browser Extension is your secure and versatile crypto wallet, unlocking access to thousands of Web3 dApps. Manage your crypto, swap tokens, explore NFTs, play blockchain games, earn rewards, and so much more—all seamlessly from your browser.</p>
                                <div className="download__app__bx">
                                    <a href="#" target="_blank">Download Extension <img src={chromeStore} alt="" />
                                        <img className="coming__soon__tag__footer" src={commingSoonTag} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="prod__banner__img__bx">
                                <img className="w-100" src={productBanner} alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="browser__extension__section">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="digital__effert__head">
                                <h3>Chrome-Exclusive Extension – Built for Performance</h3>
                                <p className="mb-3">The AND Wallet Browser Extension is designed exclusively for Google Chrome, delivering a fast, secure, and optimized experience for all users. Enjoy smooth navigation and unparalleled security as you explore the Web3 universe.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <div className="browser__extension__bx">
                                <img src={googleChrome} alt="Chrome" />
                                <p>Chrome</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="browser__extension__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="multi__chain__experience__container">
                                <div className="digital__effert__head">
                                    <h3>A True Multi-Chain Experience with AND Web Wallet</h3>
                                    <p>The AND Web Wallet is tailored for EVM-compatible blockchains, providing seamless access to leading networks like Ethereum, Polygon, Binance Smart Chain, and more. Effortlessly explore dApps, manage assets, and interact across multiple chains—all within a secure and user-friendly platform.</p>
                                </div>
                                <div className="support__chain__container">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12">

                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <div className="support__chain__bx">
                                                <img src={bnbIcon} alt="BNB" />
                                                <p>BNB Smart Chain</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <div className="support__chain__bx">
                                                <img src={avalancheIcon} alt="BNB" />
                                                <p>Avalanche C-Chain</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <div className="support__chain__bx">
                                                <img src={ethereumIcon} alt="BNB" />
                                                <p>Ethereum</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <div className="support__chain__bx">
                                                <img src={arbitrumIcon} alt="BNB" />
                                                <p>Arbitrum</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <div className="support__chain__bx">
                                                <img src={optimisticIcon} alt="BNB" />
                                                <p>Optimistic Ethereum</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="buy__crypto__section__bx">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                <h4>Buy AND with Ease – Your Gateway to Crypto</h4>
                                <p>Purchase crypto, including AND, effortlessly using your debit or credit card. With just a few clicks, you’re ready to dive into the world of Web3 and take control of your digital assets.</p>
                                <img src={product1} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                <h4>Explore and Manage NFTs – All in One Place</h4>
                                <p>Discover the best NFT marketplaces and securely manage your NFT collection. Whether it’s digital art, gaming assets, or collectibles, AND Wallet ensures your NFTs are always accessible, secure, and ready to showcase.</p>
                                <img src={product2} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                <h4>Swap Crypto Safely – Powered by Trust</h4>
                                <p>Easily swap cryptocurrencies with trusted swap providers directly within the AND Wallet. Enjoy fast, secure, and hassle-free transactions, giving you peace of mind with every trade.</p>
                                <img src={product3} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                <h4>Your NFTs, Your Way</h4>
                                <p>Effortlessly view, manage, and showcase your NFT collection. From digital art to gaming assets, AND Wallet keeps your NFTs secure, accessible, and beautifully organized.</p>
                                <img src={product4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wallet__keys__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wallet__keys__crypto__bx">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="crypto__wallet__img">
                                            <img src={cryptoWallet} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="crypto__wallet__txt">
                                            <h3>Start Your Journey with AND Wallet Today</h3>
                                            <p>Whether you’re managing crypto, exploring NFTs, or diving into Web3 dApps, the AND Wallet is your ultimate tool for crypto freedom.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewFooter />
        </div>
    )
}

export default Product