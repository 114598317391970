import { useNavigate } from "react-router-dom";
import { getFormattedDateTime } from "../../utils/helper";
import IconAND from '../../assets/images/and-icon-circle.svg'
import SendIcon from '../../assets/images/send.svg'

const TransactionList = ({ localData }) => {
    const navigate = useNavigate();

    return (
        <div className='transaction-list'>
            <div className='header-trn'>
                <h3>Latest Transaction</h3>
                <a onClick={() => { navigate("/transactions") }} className='viewall-a'>View All</a>
            </div>
            <div className='transaction-list-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            localData?.length === 0 ? <tr>
                                <td colSpan={4} style={{ textAlign: 'center' }}>Transaction not found</td>
                            </tr> :
                                localData?.map((_tx, _index) => {
                                    const _date = getFormattedDateTime(_tx?.date)
                                    return (
                                        <tr key={_index}>
                                            <td>
                                                <div className='flex-type'><img src={SendIcon} alt="send-icon" /> {_tx?.type}</div>
                                            </td>
                                            <td>
                                                <div className='flex-type'><img src={IconAND} alt="and-icon" />{_tx?.amount} AND</div>
                                            </td>
                                            <td>{_date}</td>
                                            <td>
                                                <div className='status-successful'>Successful</div>
                                            </td>
                                        </tr>
                                    )
                                })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}


export default TransactionList