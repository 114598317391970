import React from 'react'
import Slider from "react-slick";
import { ethers, isAddress } from "ethers";
import { useEffect, useState } from "react";
import MultiWalletConnector from 'multi-wallet-connector'
import { Tab, TabGroup, TabList,  TabPanels, } from '@headlessui/react'

import { ANAND_TOKEN_ADDRESS, ANAND_TOKEN_DECIMALS } from "../assets/constant";

import Notify from "../components/notification";
import Portfolio from '../components/dashboard/Portfolio';
import SendANDToken from "../components/dashboard/SendANDToken";
import TransactionList from "../components/dashboard/TransactionList";
import RecieveANDToken from "../components/dashboard/RecieveANDToken";

import BannerRight1 from '../assets/images/banner-right-1.png'
import BannerRight2 from '../assets/images/banner-right-2.png'

import { processANDSend } from "../services/swapServices";

import useLocalStorage from "../hooks/localStorage";

import { requestMetamaskNetworkChange } from "../utils/helper";
import Popup from '../components/Popup';
import "../style.css"

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 2500,
  slidesToScroll: 1
};

const DashboardComponent = ({ walletAddress }) => {
  const [activeTab, setActiveTab] = useState('send');
  const { localData, updateLocalData } = useLocalStorage("txHistory", []);

  const [isSendDisable, setIsSendDisable] = useState(true);
  const [transactionInfo, setTransactionInfo] = useState({
    receiveAddress: '',
    sendAmount: '',
    txInProcess: false
  })

  const updateTransactionInfo = (_newState = {}) => {
    setTransactionInfo((prev) => ({
      ...prev,
      ..._newState
    }))
  }

  const handleReceiverAddress = (event) => {
    updateTransactionInfo({ receiveAddress: event.target.value });
  }

  const handleSendAmount = (event) => {
    updateTransactionInfo({ sendAmount: event.target.value });
  }

  useEffect(() => {
    const { receiveAddress, txInProcess, sendAmount } = transactionInfo
    const isDisable = isNaN(parseFloat(sendAmount)) || parseFloat(sendAmount) <= 0 || !isAddress(receiveAddress) || txInProcess
    setIsSendDisable(isDisable)
  }, [transactionInfo.sendAmount, transactionInfo.receiveAddress]);

  const handleSend = async () => {
    try {
      const { sendAmount, receiveAddress } = transactionInfo;
      updateTransactionInfo({ txInProcess: true })

      const chainId = await MultiWalletConnector.getChainId();

      if (chainId !== 137) {
        await requestMetamaskNetworkChange("MATIC")
      }

      // Initialize the provider from MetaMask
      const provider = new ethers.BrowserProvider(window.ethereum);

      // Get the signer from the provider
      const signer = await provider.getSigner();

      // ERC20 contract ABI and address
      const ERC20_ABI = [
        "function balanceOf(address owner) view returns (uint256)",
        "function transfer(address to, uint256 amount) public returns (bool)",
      ];
      const tokenAddress = ANAND_TOKEN_ADDRESS;

      // Connect the contract with the signer
      const contract = new ethers.Contract(tokenAddress, ERC20_ABI, signer);

      // Transfer details
      const recipient = receiveAddress;
      const amount = ethers.parseUnits(sendAmount, ANAND_TOKEN_DECIMALS); // Adjust decimals as needed

      // Check user's token balance
      const balance = await contract.balanceOf(walletAddress);

      // Check if the user has sufficient balance
      if (balance < amount) {
        Notify("Insufficient token balance", "error");
        updateTransactionInfo({ txInProcess: false })
        return;
      }

      // Send the transaction
      const tx = await contract.transfer(recipient, amount);

      // Wait for confirmation
      await tx.wait();

      processANDSend({
        txHash: tx?.hash
      });

      const txData = {
        id: localData?.length + 1,
        hash: tx.hash,
        type: 'Send',
        amount: sendAmount,
        date: new Date(),
        walletAddress: walletAddress,
        timestamp: new Date().getTime(),
        completed: true
      }
      updateLocalData('txHistory', [txData, ...localData]);
      Notify("Transaction successful!", "success");
      updateTransactionInfo({ txInProcess: false, receiveAddress: '', sendAmount: '' });
    } catch (error) {
      Notify("Failed to send token.", "error");
      updateTransactionInfo({ txInProcess: false })
    }
  }

  return (
    <div className='content-page-inner'>
      <Portfolio walletAddress={walletAddress} />
      <div className='right-section-web'>
        <div className='right-inner'>
          <div className='send-receive-section'>
            <TabGroup>
              <div className='tablist'>
                <TabList>
                  <Tab className={activeTab === 'send' ? 'active' : ''} onClick={() => setActiveTab('send')}>Send</Tab>
                  <Tab className={activeTab === 'receive' ? 'active' : ''} onClick={() => setActiveTab('receive')}>Receive</Tab>
                </TabList>
              </div>
              <div className='tab-content-main'>
                <TabPanels>
                  <SendANDToken
                    walletAddress={walletAddress}
                    transactionInfo={transactionInfo}
                    isSendDisable={isSendDisable}
                    handleReceiverAddress={handleReceiverAddress}
                    handleSend={handleSend}
                    handleSendAmount={handleSendAmount}
                  />
                  <RecieveANDToken
                    walletAddress={walletAddress}
                  />
                </TabPanels>
              </div>
            </TabGroup>
          </div>
          <div className='bannerimg'>
            <div className="card-banr">
              <Slider {...settings}>
                <div>
                  <img src={BannerRight1} alt="banner-1" />
                </div>
                <div><img src={BannerRight2} alt="banner-2" /></div>
              </Slider>
            </div>
          </div>
        </div>
        <TransactionList localData={localData} />
      </div>
      {/* <Popup/> */}
    </div>
  )
}

export default DashboardComponent