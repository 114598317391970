import { Menu, MenuButton, TabPanel } from "@headlessui/react";
import QRCode from "react-qr-code";
import { copyTextToClipboard, emphasisAddress } from "../../utils/helper";
import IconANDIcon from '../../assets/images/and-logo-icon.svg'
import IconCopy from '../../assets/images/copy.svg'
import { useState } from "react";

const RecieveANDToken = ({ walletAddress }) => {
    const [back] = useState('#FFFFFF');
    const [fore] = useState('#000000');
    const [size] = useState(200);
    const [copied, setCopied] = useState(false);
  
    return (
      <TabPanel>
        <div className='tab-content-dv'>
          <div className='selectstyle'>
            <Menu>
              <MenuButton className="select-opsn">
                <div className='flex-opens'><img src={IconANDIcon} alt="and-logo" />AND</div>
              </MenuButton>
            </Menu>
          </div>
          <div className='qrcode'>
            <QRCode
              value={walletAddress}
              bgColor={back}
              fgColor={fore}
              size={size === '' ? 0 : size}
            />
          </div>
          <div className='request-id-content'>
            <div className='flex-address'>
              <div className='address-label'>Address:</div>
              <div className='addressnm'>{emphasisAddress(walletAddress, 10)} </div>
            </div>
            <div onClick={() => {
              copyTextToClipboard(walletAddress);
              setCopied(true);
  
              setTimeout(() => {
                setCopied(false)
              }, 1500);
            }} className='copybutton'><img src={IconCopy} alt="copy-icon" /></div>
          </div>
        </div>
      </TabPanel>
    )
  }

  export default RecieveANDToken