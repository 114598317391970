import Skeleton from "react-loading-skeleton";
import React from "react";
import SkeletonLoader from "../components/Skeleton";
import RecordNotIcon from "../assets/images/record-not-found.png"

const TableLoaderSkeleton = ({ _rowCount = 1, _columnCount = 1 }) => {
  return (
    <>
      {Array.from({ length: _rowCount })?.map((_, rowIndex) => (
        <tr key={rowIndex}>
          {Array.from({ length: _columnCount })?.map((_, colIndex) => (
            <td key={colIndex}>
              <Skeleton />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

const TableSkeleton = ({ _rowCount = 1, _columnCount = 1 }) => {
  return (
    <React.Fragment>
      {Array.from({ length: _rowCount })?.map((_, rowIndex) => (
        <tr key={rowIndex}>
          {Array.from({ length: _columnCount })?.map((_, colIndex) => (
            <td key={colIndex}>
              <SkeletonLoader />
            </td>
          ))}
        </tr>
      ))}
    </React.Fragment>
  );
};



const DataNotFoundRow = ({ _columnCount, _height }) => {
  return (
    <tr>
      <td colSpan={_columnCount}>
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{ minHeight: { _height } }}
        >
          <h5>Data Not Found</h5>
        </div>
      </td>
    </tr>
  );
};

const TableDataNotFound = ({ _columnCount, _text, _label }) => {
  return (
    <tr>
      <td colSpan={_columnCount}>
        <div className="no-transaction-space">
          <img src={RecordNotIcon} alt="data-not-found-icon" />
          <h5>{_label ? _label : "No Transaction Found."}</h5>
          <p>
            {_text}
          </p>
        </div>
      </td>
    </tr>
  )
}

export {
  TableLoaderSkeleton,
  DataNotFoundRow,
  TableSkeleton,
  TableDataNotFound
};