import React from 'react';
import './Popup.css';
import ANDIcon from '../assets/images/and-logo-icon.svg';
import MetaMaskIcon from '../assets/images/metamask.svg';

const Popup = ({ handleClose, onConnect }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-container">
                <button className="close-button" onClick={handleClose}>Close</button>
                <p className="popup-text">Please connect your wallet.</p>
                <div className="wallet-options">
                    <button className="wallet-btn" onClick={() => onConnect('AND')}>
                        <img src={ANDIcon} alt="AND" /> AND
                    </button>
                    <button className="wallet-btn" onClick={() => onConnect('MetaMask')}>
                        <img src={MetaMaskIcon} alt="MetaMask" /> MetaMask
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;