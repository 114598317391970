import { NavLink, Link } from "react-router-dom";
import anadaWhiteLogo from "../assets/images/home/aanand-logo-white.svg";
import i18n from "../i18n";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const NewHeader = () => {
    const { t } = useTranslation();
    const isSwitching = useRef(false);
    const [lang, setLang] = useState(i18n?.language)

    return (
        <header className="header__section">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="#">
                            <img src={anadaWhiteLogo} alt="Aanand Logo" />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarText"
                            aria-controls="navbarText"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/">
                                        {t('menu.home')}
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink
                                        className="nav-link dropdown-toggle"
                                        to="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {t('menu.product')}
                                    </NavLink>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <NavLink className="dropdown-item" to="/and-event-platform">
                                                {t('menu.productOption1')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="dropdown-item" to="/and-chrome-wallet">
                                                {t('menu.productOption2')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="dropdown-item" to="/and-android-wallet">
                                                {t('menu.productOption3')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink className="nav-link" to="/token">
                                        {t('menu.token')}
                                    </NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/wallet-download">
                                        {t('menu.wallet')}
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact-us">
                                        {t('menu.contact')}
                                    </NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="#">
                                        {t('menu.support')}
                                    </NavLink>
                                </li>
                            </ul>
                            <span className="navbar-text d-flex align-items-center">
                                <NavLink to="/dashboard" className="get__started_btn">
                                {t('menu.launch_app')}
                                </NavLink>
                                <div className="toggle__language">
                                    <div
                                        onClick={() => {
                                            if (isSwitching.current) return; // Prevents double execution
                                            isSwitching.current = true;

                                            setTimeout(() => {
                                                const currentLang = i18n.language;
                                                const newLang = currentLang === "ko" ? "en" : "ko";

                                                console.log("Switching language to:", newLang);
                                                i18n.changeLanguage(newLang);
                                                setLang(newLang)
                                                isSwitching.current = false; // Reset flag after execution
                                            }, 100); // Small delay to avoid duplicate calls
                                        }}

                                        className="switch">
                                        <input
                                            id="language-toggle"
                                            className="check-toggle check-toggle-round-flat"
                                            type="checkbox"
                                            checked={lang === "ko"}
                                            readOnly
                                        />
                                        <label htmlFor="language-toggle"></label>
                                        <span className="on" title="English">EN</span>
                                        <span className="off" title="Korean">KO</span>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default NewHeader
