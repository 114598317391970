// Import statements
import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { ethers, formatUnits, parseUnits } from 'ethers';
import useDebounce from '../utils/useDebounce';
import {
  NetworkList,
  TokenIcon
} from '../utils/networkList';
import {
  handleNumberValidation,
  metamaskErrorHandler,
  numberToHex,
  requestMetamaskNetworkChange
} from '../utils/helper';
import {
  generateQuote,
  processPurchaseToken
} from '../services/swapServices';
import MultiWalletConnector from 'multi-wallet-connector';
import Notify from '../components/notification';
import SkeletonLoader from '../components/Skeleton';
import { NetworkTokenModal } from '../components/NetworkTokenModal';
import { ToastContainer } from 'react-toastify';
import { ANAND_PRICE } from '../assets/constant';
import usaFlag from '../assets/images/usa-flag.png';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import IconMatic from '../assets/images/matic-icon.svg'
import IconUSDT from '../assets/images/usdt-icon.svg'
import "../style.css"

const PurchaseTokenUsingCrypto = () => {
  const [isNetworkModalVisible, setNetworkModalVisible] = useState(false);
  const [activeNetwork, setActiveNetwork] = useState(NetworkList[0]);
  const [tokenList, setTokenList] = useState(NetworkList[0]?.tokenList);
  const [activeToken, setActiveToken] = useState(NetworkList[0]?.tokenList[0]);
  const [swapData, setSwapData] = useState({
    loading: false,
    calculating: false,
    fromTokenAmount: '',
    fromUsdAmount: 0,
    toTokenAmount: '',
    toUsdAmount: 0,
    disabled: true
  });
  const [quoteResponse, setQuoteResponse] = useState(null);
  const debouncedFromTokenAmount = useDebounce(swapData.fromTokenAmount, 700);

  const handleClose = async (network, token, isClose) => {
    setActiveNetwork(network);
    setActiveToken(token);
    setTokenList(network.tokenList);
    setNetworkModalVisible(!isClose);

    if (debouncedFromTokenAmount > 0) {
      const asset = token.isNative ? 'NATIVE' : token.tokenSymbol;
      await fetchQuote(network.chainId, asset, debouncedFromTokenAmount, token.tokenDecimal);
    }
  };

  const handleFromAmount = (event) => {
    const amount = event.target.value;
    setSwapData((prev) => ({
      ...prev,
      fromTokenAmount: amount,
      fromUsdAmount: amount > 0 ? prev.fromUsdAmount : 0,
      toTokenAmount: amount > 0 ? prev.toTokenAmount : '',
      toUsdAmount: amount > 0 ? prev.toUsdAmount : 0
    }));
  };

  const fetchQuote = async (chainId, asset, amount, tokenDecimal) => {
    setSwapData((prev) => ({ ...prev, calculating: true }));

    try {
      const decimalAmount = parseUnits(amount, tokenDecimal || 0);
      const hexNumber = numberToHex(decimalAmount.toString());
      const quote = await generateQuote(chainId, asset, hexNumber);

      if (quote.status === 'success') {
        const tokenAmount = formatUnits(quote.data.tokenAmount);
        const usdValue = tokenAmount * ANAND_PRICE;
        setQuoteResponse(quote.data);
        setSwapData((prev) => ({
          ...prev,
          fromUsdAmount: usdValue,
          toTokenAmount: tokenAmount,
          toUsdAmount: usdValue,
          calculating: false
        }));
      } else {
        setSwapData((prev) => ({
          ...prev,
          fromUsdAmount: 0,
          toTokenAmount: '',
          toUsdAmount: 0,
          calculating: false
        }));
        Notify('Error while generating quote', 'error');
      }
    } catch (error) {
      console.error('Error fetching quote:', error);
      setSwapData((prev) => ({ ...prev, calculating: false }));
      Notify('Failed to fetch quote', 'error');
    }
  };

  const handleNetworkChangeIfNeeded = async (fromChainId, fromSymbol, currentChainId) => {
    if (parseInt(fromChainId) !== parseInt(currentChainId)) {
      await requestMetamaskNetworkChange(fromSymbol);
      const newChainId = await MultiWalletConnector.getChainId();
      if (parseInt(fromChainId) !== newChainId) {
        Notify('Network switch cancelled in Metamask.', 'error');
        return false;
      }
    }
    return true;
  };

  const handlePurchase = async () => {
    setSwapData((prev) => ({ ...prev, loading: true }));

    try {
      const chainId = await MultiWalletConnector.getChainId();
      const networkChanged = await handleNetworkChangeIfNeeded(
        activeNetwork.chainId,
        activeNetwork.symbol,
        chainId
      );

      if (!networkChanged) {
        setSwapData((prev) => ({ ...prev, loading: false }));
        return;
      }

      const purchaseFunction = activeToken.isNative ? handleNativePurchase : handleERC20Purchase;
      const success = await purchaseFunction();

      if (success) Notify('Transaction Completed Successfully', 'success');
    } catch (error) {
      Notify(metamaskErrorHandler(error), 'error');
    } finally {
      setSwapData((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleNativePurchase = async () => {
    try {
      const { contractAddress, assetAddress, quoteId } = quoteResponse;
      const amount = parseUnits(swapData.fromTokenAmount, activeToken.tokenDecimal);

      const provider = new ethers.BrowserProvider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(contractAddress, [quoteResponse.functionFragmentAbi], signer);

      const txResponse = await contract.purchaseTokens(assetAddress, amount, quoteId, {
        value: activeToken.isNative ? amount : '0'
      });

      const receipt = await txResponse.wait();
      await processPurchaseToken({ chainId: activeNetwork.chainId, txHash: receipt.hash });

      return true;
    } catch (error) {
      console.error('Error in native purchase:', error);
      Notify(metamaskErrorHandler(error), 'error');
      return false;
    }
  };

  const handleERC20Purchase = async () => {
    try {
      const { contractAddress, assetAddress } = quoteResponse;
      const amount = parseUnits(swapData.fromTokenAmount, activeToken.tokenDecimal);

      const _address = MultiWalletConnector.getActiveAddress();
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const tokenContract = new ethers.Contract(assetAddress, [
        'function allowance(address owner, address spender) view returns (uint256)',
        'function approve(address spender, uint256 amount) returns (bool)'
      ], signer);

      const currentAllowance = await tokenContract.allowance(_address, contractAddress);

      if (parseFloat(currentAllowance) < parseFloat(amount)) {
        const approvalTx = await tokenContract.approve(contractAddress, amount);
        await approvalTx.wait();
      }

      return await handleNativePurchase();
    } catch (error) {
      console.error('Error in ERC20 purchase:', error);
      Notify(metamaskErrorHandler(error), 'error');
      return false;
    }
  };

  useEffect(() => {
    if (debouncedFromTokenAmount) {
      const asset = activeToken.isNative ? 'NATIVE' : activeToken.tokenSymbol;
      fetchQuote(activeNetwork.chainId, asset, debouncedFromTokenAmount, activeToken.tokenDecimal);
    }
  }, [debouncedFromTokenAmount]);

  useEffect(() => {
    setSwapData((prev) => ({
      ...prev,
      disabled: swapData.fromTokenAmount <= 0
    }));
  }, [swapData.fromTokenAmount]);

  return (
    <div className='swap-modal-body'>
      <div className=''>
        <div className='main-inputbox'>
          <div className='input-form m-none'>
            <div className='crypto-input'>
              <div onClick={() => setNetworkModalVisible(true)} className='listing-portfolio-left'>
                <div className='walleticon-circle'>
                  <img src={activeToken?.icon} alt='active-icon' />
                  <img className='networkicon-port' src={activeNetwork?.icon} alt='active-network' />
                </div>
                <div className='listing-portfolio-icons'>
                  <h5>{activeToken?.tokenSymbol}</h5>
                  <p>{activeNetwork?.symbol}</p>
                </div>
                <button className='arrowdown'> <ChevronDownIcon /></button>
              </div>
              <input onKeyDown={handleNumberValidation} value={swapData?.fromTokenAmount} onChange={handleFromAmount} placeholder='0.0' />
            </div>
            {swapData?.calculating ? <p> <SkeletonLoader width={10} /> </p> : <p>${swapData?.fromUsdAmount}</p>}
          </div>
        </div>
        <div className='main-inputbox'>
          <div className='estimated-text'>
            <p>Estimated Rate:</p>
            <h3>1 AND = ${ANAND_PRICE}</h3>
          </div>
          <div className='input-form text-right-label'>
            <label>You will receive</label>
            <div className='crypto-input'>
              <div className='listing-portfolio-left'>
                <div className='walleticon-circle'>
                  <img src={TokenIcon?.AND} alt='active-token' />
                  <img className='networkicon-port' src={IconMatic} alt='active-token' />
                </div>
                <div className='listing-portfolio-icons'>
                  <h5>AND</h5>
                  <p>Polygon</p>
                </div>
              </div>
              {swapData?.calculating ? <div className='input-skeltn'><SkeletonLoader /></div> :
                <input onKeyDown={handleNumberValidation} disabled value={swapData?.toTokenAmount} placeholder='0.0' />}
            </div>
            {swapData?.calculating ? <p> <SkeletonLoader width={10} /> </p> : <p>${swapData?.toUsdAmount}</p>}
          </div>
          <button disabled={swapData?.loading || swapData?.calculating || swapData?.disabled} onClick={handlePurchase} className='btn-send'>{swapData?.calculating ? 'Calculating...' : swapData?.loading ? "Submitting" : "Buy Now"}</button>
        </div>
        <p className='swapmodal-p'>The AND project is an innovative platform aimed at the global spread of K-pop and the expansion of fandom through it.</p>
      </div>
      {isNetworkModalVisible &&
        <NetworkTokenModal
          _handleClose={handleClose}
          _tokenList={tokenList}
          _activeToken={activeToken}
          _activeNetwork={activeNetwork} />
      }
    </div>
  )
}

const PurchaseTokenUsingCreditCard = ({ toggleDropdown, isDropdownOpen }) => {
  return (
    <div className='send-receive-section'>
      <div className="coming-soon">Coming Soon</div>
      <div className='tab-content-dv'>
        <div className='label-txt'>Country</div>
        <div className='selectflag'>
          <div className='select-head' onClick={toggleDropdown}>
            <div className='flex-opens'><img src={usaFlag} alt='usd-flag' />USA</div>
            <ChevronDownIcon className={` ${isDropdownOpen ? 'rotate-180' : ''}`} />
          </div>
          {isDropdownOpen && (
            <ul className='select-body'>
              <li> <div className='flex-opens mb-3'><img src={usaFlag} alt='usd-flag' />USA</div></li>
              <li> <div className='flex-opens mb-3'><img src={usaFlag} alt='usd-flag' />USA</div></li>
            </ul>
          )}
        </div>
        <div className='input-form'>
          <label>Card Holder Name</label>
          <input placeholder='Enter Card Holder Name' />
        </div>
        <div className='input-form'>
          <label>Amount</label>
          <input placeholder='Enter Amount' />
          {/* <div className='dllr-app-amt'>$</div> */}
        </div>
        <div className='input-form '>
          <label>You will receive</label>
          <div className='crypto-input'>
            <div className='listing-portfolio-left'>
              <div className='walleticon-circle'>
                <img src={IconUSDT} alt='usd-icon' />
                <img className='networkicon-port' src={IconMatic} alt='network-icon' />
              </div>
              <div className='listing-portfolio-icons'>
                <h5>Matic</h5>
                <p>USDT</p>
              </div>
            </div>
            <input placeholder='0.0' />
          </div>
          <p>$45.090</p>

        </div>
        <button className='btn-send'>Buy Now</button>
        <p className='swapmodal-p'>The AND project is an innovative platform aimed at the global spread of K-pop and the expansion of fandom through it.</p>
      </div>
    </div>
  )
}

const PurchaseTokenUsingDebitCard = () => {
  return (
    <div className='send-receive-section'>
      <div className="coming-soon">Coming Soon</div>
      <div className='tab-content-dv'>

        <div className='input-form'>
          <label>Card Number</label>
          <input placeholder='Enter Card Number' />
        </div>
        <div className='input-form'>
          <label>Card Holdername</label>
          <input placeholder='Enter Card Holdername' />
        </div>
        <div className='flexinputdiv'>
          <div className='input-form'>
            <label>MM</label>
            <input placeholder='MM' />
          </div>
          <div className='input-form'>
            <label>YYYY</label>
            <input placeholder='YYYY' />
          </div>
          <div className='input-form'>
            <label>CVV</label>
            <input placeholder='CVV' />
          </div>
        </div>

        <button className='btn-send'>Pay Now</button>
        <p className='swapmodal-p'>The AND project is an innovative platform aimed at the global spread of K-pop and the expansion of fandom through it.</p>
      </div>
    </div>
  )
}

const TABLIST = [{ label: 'Crypto', isActive: true }, { label: 'Debit Card', isActive: false }, { label: 'Credit Card', isActive: false }]
const Swap = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tabs, setTabs] = useState(TABLIST);
  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const handleTabs = (_index) => {
    let _tabs = [...TABLIST];
    _tabs[0].isActive = false;
    _tabs[1].isActive = false;
    _tabs[2].isActive = false;
    _tabs[_index].isActive = true;
    setTabs(_tabs)
  }

  return (
    <React.Fragment>
      <div className='swapmodal mainswap-mdl'>
        <div className='swap-modal-main'>
          <TabGroup>
            <div className='tablist'>
              <TabList>
                {
                  tabs?.map((_tab, _index) => {
                    return (
                      <Tab key={_index} onClick={() => handleTabs(_index)} className={_tab?.isActive ? 'active' : ''}>{_tab?.label}</Tab>
                    )
                  })
                }
              </TabList>
            </div>
            <div className='tab-content-main'>
              <TabPanels>
                <TabPanel>
                  <PurchaseTokenUsingCrypto />
                </TabPanel>
                <TabPanel>
                  <PurchaseTokenUsingCreditCard toggleDropdown={toggleDropdown} isDropdownOpen={isDropdownOpen} />
                </TabPanel>
                <TabPanel>
                  <PurchaseTokenUsingDebitCard />
                </TabPanel>
              </TabPanels>
            </div>
          </TabGroup>

        </div>
      </div>
      <ToastContainer />

    </React.Fragment>
  )
}

export default Swap
