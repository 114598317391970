import React, { useEffect, useState } from 'react';
import IconAND from '../assets/images/and-icon-circle.svg';
import SendIcon from '../assets/images/send.svg';
import { emphasisAddress, getFormattedDateTime, isFloat, truncateNumber } from '../utils/helper';
import { getPurchaseTxList } from '../services/swapServices';
import { ExplorerList, NetworkByChaindId, TokenAddressList, TokenIcon } from '../utils/networkList';
import { formatUnits, ZeroAddress } from 'ethers';
import { TableLoaderSkeleton } from '../utils/tableElements';
import Pagination from '../components/pagination';
import "../style.css"

const Transactions = ({ walletAddress }) => {
  const [txList, setTxList] = useState({
    loading: false,
    dataNotFound: false,
    data: [],
    meta: {},
  });

  const getTransactionList = async (page = 1, limit = 10) => {
    if (!walletAddress) {
      setTxList({ loading: false, dataNotFound: true, data: [], meta: {} });
      return;
    }

    try {
      setTxList((prev) => ({ ...prev, loading: true }));

      const response = await getPurchaseTxList(page, limit, walletAddress);
      const { status, data } = response || {};

      if (status === 'success' && data?.data?.length) {
        setTxList({
          loading: false,
          dataNotFound: false,
          data: data.data,
          meta: data.meta,
        });
      } else {
        setTxList({ loading: false, dataNotFound: true, data: [], meta: {} });
      }
    } catch (error) {
      console.error('Error fetching transaction list:', error);
      setTxList({ loading: false, dataNotFound: true, data: [], meta: {} });
    }
  };

  useEffect(() => {
    getTransactionList();
  }, []);

  const renderTransactionRow = (transaction, index) => {
    const {
      chainId,
      assetAmount,
      tokenAmount,
      transactionHash,
      fromAddress,
      createdAt,
      assetAddress,
    } = transaction;

    if (!assetAmount) return null;

    const network = NetworkByChaindId[chainId];
    const isNative = assetAddress === ZeroAddress || network === 'BSC';
    const sourceAmount = formatUnits(assetAmount, isNative ? 18 : 6);
    const tokenAmountFormatted = formatUnits(tokenAmount, 18);

    const tokenSymbol = !isNative
      ? TokenAddressList[network === 'Polygon' ? 'MATIC' : network]?.['USDT'] === assetAddress
        ? 'USDT'
        : 'USDC'
      : network;

    return (
      <tr key={index}>
        <td>
          <div
            className="flex-type transaction-link"
            onClick={() => window.open(`${ExplorerList[network]}/tx/${transactionHash}`)}
          >
            <img src={SendIcon} alt="send-icon" /> {emphasisAddress(transactionHash)}
          </div>
        </td>
        <td>
          <div className="flex-type">{emphasisAddress(fromAddress)}</div>
        </td>
        <td>
          <div className="flex-type">
            <img src={TokenIcon[network]} alt={`${network}-icon`} />
            {network}
          </div>
        </td>
        <td>
          <div className="flex-type">
            <img src={TokenIcon[tokenSymbol]} alt={`${tokenSymbol}-icon`} />
            {isFloat(Number(sourceAmount)) ? Number(sourceAmount).toFixed(4) : sourceAmount} {tokenSymbol}
          </div>
        </td>
        <td>
          <div className="flex-type">
            <img src={IconAND} alt="and-icon" />
            {isFloat(Number(tokenAmountFormatted))
              ? truncateNumber(tokenAmountFormatted, 4)
              : tokenAmountFormatted}{' '}
            AND
          </div>
        </td>
        <td>{getFormattedDateTime(createdAt)}</td>
      </tr>
    );
  };

  return (
    <div className="content-page-inner" style={{flexDirection:'column'}}>
      <div className="transaction-list">
        <div className="header-trn">
          <h3>Transactions</h3>
        </div>
        <div className="transaction-list-table">
          <table>
            <thead>
              <tr>
                <th>Transaction Hash</th>
                <th>Address</th>
                <th>Source Network</th>
                <th>Source Amount</th>
                <th>AND Received</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {txList.loading ? (
                <TableLoaderSkeleton _columnCount={6} _rowCount={10} />
              ) : txList.dataNotFound ? (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    Transactions not found
                  </td>
                </tr>
              ) : (
                txList.data.map(renderTransactionRow)
              )}
            </tbody>
          </table>
        </div>
        {!txList.dataNotFound && (
          <Pagination
            metaData={txList.meta}
            limit={txList.meta?.perPage}
            handlePageChange={(nextPage, limit) => getTransactionList(nextPage, limit)}
          />
        )}
      </div>
    </div>
  );
};

export default Transactions;
