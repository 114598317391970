import React from 'react'

import NewHeader from '../components/NewHeader';
import NewFooter from '../components/NewFooter';
import { Link } from 'react-router-dom';
import commingSoonTag from '../assets/images/home/coming-soon-tag.png'
import ProdBannerImage from '../assets/images/home/prod-banner-img.png'
import GoogleChromeBlue from '../assets/images/home/google-chrome.png'

import BnbLogo from '../assets/images/home/bnb-logo.png'
import AvalancheChainLogo from '../assets/images/home/avalanche-c-chain-logo.png'
import EthereumLogo from '../assets/images/home/ethereum-logo.png'
import Arbitrumlogo from '../assets/images/home/arbitrum-logo.png'
import OptimisticLogo from '../assets/images/home/optimistic-ethereum-logo.png'
import ProdLogo1 from '../assets/images/home/prod-1.png'
import ProdLogo2 from '../assets/images/home/prod-2.png'
import ProdLogo3 from '../assets/images/home/prod-3.png'
import ProdLogo4 from '../assets/images/home/prod-4.png'
import CryptoWallet from '../assets/images/home/crypto-wallet.png'
import { useTranslation } from 'react-i18next';

const AndChromeWallet = () => {
    const { t } = useTranslation();

    // return (
    //   <div>
    //     <NewHeader />
    //     {/* Hero Section */}
    //     <section className="product__banner__section">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-md-6">
    //             <div className="hero__banner__head">
    //               <h1>{t("chromeWallet.title")}</h1>
    //               <p>{t("chromeWallet.description")}</p>
    //               <div className="download__app__bx">
    //                 <Link href="#" target="_blank">
    //                   {t("chromeWallet.chromeTitle")}
    //                   <img className="coming__soon__tag__footer" src={commingSoonTag} alt="" />
    //                 </Link>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col-md-6">
    //             <div className="prod__banner__img__bx">
    //               <img className="w-100" src={ProdBannerImage} alt="Banner" />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>

    //     {/* Chrome Extension Section */}
    //     <section className="browser__extension__section">
    //       <div className="container">
    //         <div className="row mb-4">
    //           <div className="col-md-12">
    //             <div className="digital__effert__head">
    //               <h3>{t("chromeWallet.eventPage")}</h3>
    //               <p className="mb-3">{t("chromeWallet.description")}</p>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="row justify-content-center">
    //           <div className="col-md-3">
    //             <div className="browser__extension__bx">
    //               <img src={GoogleChromeBlue} alt="Chrome" />
    //               <p>Chrome</p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>

    //     {/* Multi-Chain Experience */}
    //     <section className="browser__extension__section">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-md-12">
    //             <div className="multi__chain__experience__container">
    //               <div className="digital__effert__head">
    //                 <h3>{t("chromeWallet.focus")}</h3>
    //                 <p>{t("chromeWallet.focusDescription")}</p>
    //               </div>
    //               <div className="support__chain__container">
    //                 <div className="row justify-content-center">
    //                   <div className="col-md-2 mb-3 mt-3">
    //                     <div className="support__chain__bx">
    //                       <img src={BnbLogo} alt="BNB" />
    //                       <p>BNB Smart Chain</p>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-2 mb-3 mt-3">
    //                     <div className="support__chain__bx">
    //                       <img src={AvalancheChainLogo} alt="Avalanche" />
    //                       <p>Avalanche C-Chain</p>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-2 mb-3 mt-3">
    //                     <div className="support__chain__bx">
    //                       <img src={EthereumLogo} alt="Ethereum" />
    //                       <p>Ethereum</p>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-2 mb-3 mt-3">
    //                     <div className="support__chain__bx">
    //                       <img src={Arbitrumlogo} alt="Arbitrum" />
    //                       <p>Arbitrum</p>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-2 mb-3 mt-3">
    //                     <div className="support__chain__bx">
    //                       <img src={OptimisticLogo} alt="Optimism" />
    //                       <p>Optimistic Ethereum</p>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>

    //     <NewFooter />
    //   </div>
    // );
    return (
        <div>
            <NewHeader />

            <section className="product__banner__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="hero__banner__head">
                                <h1>{t("chromeWallet.title")}</h1>
                                <p>{t("chromeWallet.description")}</p>

                                <div className="download__app__bx">
                                    <Link href="#" target="_blank">{t("chromeWallet.chromeTitle")}
                                        <img className="coming__soon__tag__footer" src={commingSoonTag} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="prod__banner__img__bx">
                                <img className="w-100" src={ProdBannerImage} alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="browser__extension__section">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="digital__effert__head">

                                <h3>{t("chromeWallet.eventPage")}</h3>
                                <p className="mb-3">{t("chromeWallet.eventPageDescription")}</p>
                                {/* <h3>Chrome-Exclusive Extension – Built for Performance</h3> */}
                                {/* <p className="mb-3">The AND Wallet Browser Extension is designed exclusively for Google Chrome, delivering a fast, secure, and optimized experience for all users. Enjoy smooth navigation and unparalleled security as you explore the Web3 universe.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <div className="browser__extension__bx">
                                <img src={GoogleChromeBlue} alt="Chrome" />
                                <p>Chrome</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="browser__extension__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="multi__chain__experience__container">
                                <div className="digital__effert__head">
                                    {/* <h3>A True Multi-Chain Experience with AND Web Wallet</h3> */}
                                    {/* <p>The AND Web Wallet is tailored for EVM-compatible blockchains, providing seamless access to leading networks like Ethereum, Polygon, Binance Smart Chain, and more. Effortlessly explore dApps, manage assets, and interact across multiple chains—all within a secure and user-friendly platform.</p> */}
                                    <h3>{t('chromeWallet.focus')}</h3>
                                    <p>{t('chromeWallet.focusDescription')}</p>
                                </div>
                                <div className="support__chain__container">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12">

                                        </div>
                                        <div className="col-md-2 mb-3 mt-3">
                                            <div className="support__chain__bx">
                                                <img src={BnbLogo} alt="BNB" />
                                                <p>BNB Smart Chain</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3 mt-3">
                                            <div className="support__chain__bx">
                                                <img src={AvalancheChainLogo} alt="BNB" />
                                                <p>Avalanche C-Chain</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3 mt-3">
                                            <div className="support__chain__bx">
                                                <img src={EthereumLogo} alt="BNB" />
                                                <p>Ethereum</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3 mt-3">
                                            <div className="support__chain__bx">
                                                <img src={Arbitrumlogo} alt="BNB" />
                                                <p>Arbitrum</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2 mb-3 mt-3">
                                            <div className="support__chain__bx">
                                                <img src={OptimisticLogo} alt="BNB" />
                                                <p>Optimistic Ethereum</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="buy__crypto__section__bx">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                {/* <h4>Buy AND with Ease – Your Gateway to Crypto</h4>
                                <p>Purchase crypto, including AND, effortlessly using your debit or credit card. With just a few clicks, you’re ready to dive into the world of Web3 and take control of your digital assets.</p> */}
                               
                                <h4>{t('chromeWallet.option1')}</h4>
                                <p>{t('chromeWallet.option1Description')}</p>
                                <img src={ProdLogo1} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                {/* <h4>Explore and Manage NFTs – All in One Place</h4>
                                <p>Discover the best NFT marketplaces and securely manage your NFT collection. Whether it’s digital art, gaming assets, or collectibles, AND Wallet ensures your NFTs are always accessible, secure, and ready to showcase.</p> */}
                                <h4>{t('chromeWallet.option2')}</h4>
                                <p>{t('chromeWallet.option2Description')}</p>
                                <img src={ProdLogo2} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                            <h4>{t('chromeWallet.option3')}</h4>
                            <p>{t('chromeWallet.option3Description')}</p>
                                {/* <h4>Swap Crypto Safely – Powered by Trust</h4>
                                <p>Easily swap cryptocurrencies with trusted swap providers directly within the AND Wallet. Enjoy fast, secure, and hassle-free transactions, giving you peace of mind with every trade.</p> */}
                                <img src={ProdLogo3} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                            <h4>{t('chromeWallet.option4')}</h4>
                            <p>{t('chromeWallet.option4Description')}</p>
                                {/* <h4>Your NFTs, Your Way</h4>
                                <p>Effortlessly view, manage, and showcase your NFT collection. From digital art to gaming assets, AND Wallet keeps your NFTs secure, accessible, and beautifully organized.</p> */}
                                <img src={ProdLogo4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wallet__keys__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wallet__keys__crypto__bx">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="crypto__wallet__img">
                                            <img src={CryptoWallet} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="crypto__wallet__txt">
                                        <h3>{t('eventPage.journey')}</h3>
                                        <p>{t('eventPage.journeyDescription')}</p>
                                            {/* <h3>Start Your Journey with AND Wallet Today</h3>
                                            <p>Whether you're managing crypto, exploring NFTs, or diving into Web3 dApps, the AND Wallet is your ultimate tool for crypto freedom.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewFooter />
        </div>
    )
}

export default AndChromeWallet
