import React, { useEffect, useState } from 'react'
import MultiWalletConnector from 'multi-wallet-connector'
import SearchIcon from '../assets/images/search.svg'
import Popup from './Popup';
import "../style.css"

const Header = ({ walletAddress, handleConnect }) => {
    // State to track dark mode
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    // Update body class when dark mode state changes
    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.remove('darktheme');
        } else {
            document.body.classList.add('darktheme');
        }
    }, [isDarkMode]); // Run this effect whenever isDarkMode changes

    // Handle dark mode toggle
    const handleDarkModeToggle = () => {
        setIsDarkMode(prevMode => !prevMode); // Toggle dark mode
    };


    const handlePopup = async () => {

        try {
            if (isConnected) {

                if (MultiWalletConnector?.isMetamaskActive()) {
                    MultiWalletConnector.disconnectWallet();
                }
                else {
                    await window.AND.disconnect()
                }
                setIsConnected(false)
            }
            else {
                setShowPopup(true)
            }

        } catch (error) {
            console.log("🚀 ~ handleConnectAND ~ error:", error)

        }
    }


    const handleWalletConnect = async (walletType) => {
        console.log("🚀 ~ handleWalletConnect ~ wallet:", walletType)

        try {

            if (walletType === "MetaMask") {
                console.log("🚀 ~ handleWalletConnect ~ wallet:", walletType)
                let wallet = await MultiWalletConnector.connectWallet('Metamask', "Connect Wallet");
                console.log("🚀 ~ handleWalletConnect ~ wallet:", wallet)
                if (wallet?.status) {
                    // setWalletAddress(wallet?.walletAddress)
                    localStorage?.setItem('walletAddress', wallet?.walletAddress);
                    setIsConnected(true);
                    setShowPopup(false)

                }
            }

            if (walletType === "AND") {
                let wallet = await window.AND.connect();
                setIsConnected(wallet?.data?.isConnected)
                setShowPopup(false)
            }

        } catch (error) {
            console.log("🚀 ~ handleWalletConnect ~ error:", error)

        }
    }
    useEffect(() => {
        (async () => {
            console.log("1")
            await new Promise(resolve => setTimeout(resolve, 3000));

            if (MultiWalletConnector?.isMetamaskActive()) {
                setIsConnected(true)
            }
            else {
                const response = await window?.AND?.isConnected();
                setIsConnected(response?.data?.isConnected)
            }
        })()
    }, []);

    return (
        <div className='connect-walletbtn'>
            <div className="togglebutton">
                <div class="check-box">
                    <input
                        type="checkbox"
                        checked={isDarkMode}
                        onChange={handleDarkModeToggle} // Toggle dark mode on checkbox change
                    />
                </div>
                <label>Light Mode</label>
            </div>
            <div className='searchrightbutton'>
                {/* <div className='searchbox'>
                <input placeholder='Search by Address / Txn Hash' />
                <button><img src={SearchIcon} alt='search-icon'/></button>
            </div>  */}
                {/* <button onClick={handleConnect} className='walletbutton'>{walletAddress ? "Disconnect" : "Connect"}</button> */}
                <button onClick={handlePopup} className='walletbutton'>{isConnected ? "Disconnect" : "Connect"}</button>
            </div>
            {showPopup && <Popup
                handleClose={() => setShowPopup(false)}
                onConnect={handleWalletConnect}
            />}
        </div>
    )
}

export default Header