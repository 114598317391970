import { ANAND_PRICE } from '../assets/constant';

const fetch = require('node-fetch');

// Define cryptocurrencies and fiat currency
const cryptos = ["BNB", "MATIC", "USDT", "USDC", "ETH"];
const fiat = "USD";

// Construct the API URL
const apiKey = "68c845306709193ebbbda1919b0dce288918e88b9c962881243f5b9492e5e895"; // Replace with your API key
const url = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${cryptos.join(",")}&tsyms=${fiat}&api_key=${apiKey}`;

// Fetch the data
async function getUsdValues() {
    try {
        const response = await fetch(`${url}`, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // Add custom value for AND token
        return { ...data, 'AND': { 'USD': ANAND_PRICE } };
    } catch (error) {
        console.error("Error fetching USD values:", error);
        return null; // Return null or handle error appropriately
    }
}

// Call the function
export {
    getUsdValues
}