import React from 'react'

import TelegramIcon from '../assets/images/telegram.svg'
import EmailIcon from '../assets/images/email.svg'
import "../style.css"

 const Footer = () => {
    return (
        <div className='footer-section'>
            <div className='support-label'>Support:</div>
            <div className='flex-list-support'>
                <div className='flex-socials'>  <img src={TelegramIcon} alt='telegram-icon'/>Telegram</div>
                <div className='flex-socials'>  <img src={EmailIcon} alt='email-icon'/>Email</div>
            </div>
        </div>
    )
}

export default Footer
