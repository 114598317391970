import React, { useEffect, useState } from 'react'
import commingSoonTag from '../assets/images/home/coming-soon-tag.png'
import productBanner from '../assets/images/home/prod-banner-img.png'
import chromeStore from '../assets/images/home/chrome__icon.svg'

import NewHeader from '../components/NewHeader';
import '../assets/styles/home.css'
import NewFooter from '../components/NewFooter'

const Token = () => {
 
    return (
        <div>
          <NewHeader/>

            <section className="product__banner__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="hero__banner__head">
                                <h1>Explore Web3 with the AND Wallet – Your Gateway to True Crypto Freedom</h1>
                                <p>The AND Wallet Browser Extension is your secure and versatile crypto wallet, unlocking access to thousands of Web3 dApps. Manage your crypto, swap tokens, explore NFTs, play blockchain games, earn rewards, and so much more—all seamlessly from your browser.</p>
                                <div className="download__app__bx">
                                    <a href="#" target="_blank">Download Extension <img src={chromeStore} alt=""/>
                                        <img className="coming__soon__tag__footer" src={commingSoonTag} alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="prod__banner__img__bx">
                                    <img className="w-100" src={productBanner} alt="Banner"/>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
      



           <NewFooter/>
        </div>
    )
}

export default Token