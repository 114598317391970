import React, { useEffect, useRef, useState } from 'react'
import anadaIcon from '../assets/images/home/aanand-icon.png'
import cubeIcon from '../assets/images/home/cube-icon.png'
import walletIcon from '../assets/images/home/wallet__icon.png'
import commingSoonTag from '../assets/images/home/coming-soon-tag.png'
import placeCardIcon from '../assets/images/home/placard__icon.png'
import sortIcon from '../assets/images/home/sort.png'
// import tokenChart from '../assets/images/home/token-chart.png'
// import digital1 from '../assets/images/home/digital-1.png'
// import digital2 from '../assets/images/home/digital-2.png'
// import digital3 from '../assets/images/home/digital-3.png'
// import copyIcon from '../assets/images/home/copy_icon.png'
import globalMarket from '../assets/images/home/global-market.png'
import nowBlogs from '../assets/images/home/nowblog-icon.svg'
import nowWallet from '../assets/images/home/nowwallet-icon.svg'
import nowTracker from '../assets/images/home/nowtracker-icon.svg'


import emailIcon from '../assets/images/home/email-blue_icon.svg'
import locationIcon from '../assets/images/home/location-blue_icon.svg'

import NewHeader from '../components/NewHeader';
import NewFooter from '../components/NewFooter'
import '../assets/styles/home.css'
import { useTranslation } from 'react-i18next'
import { NetworkList } from '../utils/networkList'
import { truncateNumber } from '../utils/helper'
import AboutToken from '../components/AboutToken'
import Notify from '../components/notification'


const ContactUs = () => {
    const { t } = useTranslation();
    const formRef = useRef(null);

    // Controlled input state
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });

    // Handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted Data:", formData);

        // Show notification
        Notify("Data submitted successfully.", "success");

        // Reset form state
        setFormData({ name: "", email: "", subject: "", message: "" });

        // Reset using ref (optional)
        if (formRef.current) {
            formRef.current.reset();
        }
    };

    return (
        <section className="get__touch__section">
            <div className="container">
                <div className="row">
                    {/* Contact Details */}
                    <div className="col-md-6">
                        <div className="get__in__touch__content">
                            <h3>{t("contactUs.title")}</h3>
                            <p>{t("contactUs.description")}</p>

                            <div className="get__in__touch__email__bx">
                                <div className="get__email__bx">
                                    <img src={emailIcon} alt="Email" />
                                    <div className="email__bx">
                                        <h6>{t("contactUs.email")}</h6>
                                        <a href="mailto:info@aanands.com">info@aanands.com</a>
                                    </div>
                                </div>
                                <div className="get__email__bx">
                                    <img src={locationIcon} alt="Location" />
                                    <div className="email__bx">
                                        <h6>{t("contactUs.location")}</h6>
                                        <a href="#">{t("contactUs.seoul")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Contact Form */}
                    <div className="col-md-6">
                        <div className="form__footer__bx">
                            <form ref={formRef} onSubmit={handleSubmit} className="cust_-footer__form">
                                <div className="mb-3">
                                    <label htmlFor="Name" className="form-label">{t("contactUs.name")}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Email" className="form-label">{t("contactUs.email")}</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Subject" className="form-label">{t("contactUs.subject")}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Message" className="form-label">{t("contactUs.message")}</label>
                                    <textarea
                                        className="form-control"
                                        id="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        rows="3"
                                        required
                                    ></textarea>
                                </div>
                                <div className="mb-3">
                                    <button type="submit" className="btn btn__submit__frm">
                                        {t("contactUs.submit")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};



const HomeBanner = () => {
    const { t } = useTranslation();

    return (
        <section className="hero__banner__section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="hero__banner__head">
                            <h1>{t("homeBanner.title")}</h1>
                            <p>{t("homeBanner.description")}</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-4 col-lg-3">
                        <a href="/dashboard" className="banner__btn__l__app" target="_blank">
                            <div className="banner__bx launch__app__bx">
                                <div className="banner__bx__head">
                                    <img className="aanand__icon__banner" src={anadaIcon} alt="" />
                                    <h4>{t("homeBanner.launch_app")}</h4>
                                </div>
                            </div>
                        </a>
                        <a href="/buy-and" className="banner__btn__l__app buy__and__banner__btn" target="_blank">
                            <div className="banner__bx">
                                <div className="banner__bx__head">
                                    <div className="hexagon"></div>
                                    <h4>{t("homeBanner.buy_and")}</h4>
                                </div>
                            </div>
                            <div className="banner__txt__p">
                                <p>{t("homeBanner.limited_offer")}</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4 col-lg-6">
                        <div className="banner__qube__img__section">
                            <img src={cubeIcon} alt="Cube Image" />
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                        <a href="#" className="banner__btn__l__app" target="_blank">
                            <div className="banner__bx">
                                <div className="banner__bx__head">
                                    <img className="aanand__icon__banner" src={walletIcon} alt="" />
                                    <h4>{t("homeBanner.wallet")}</h4>
                                </div>
                                <img className="coming__soon__tag" src={commingSoonTag} alt="" />
                            </div>
                        </a>
                        <a href="#" className="banner__btn__l__app" target="_blank">
                            <div className="banner__bx">
                                <div className="banner__bx__head">
                                    <img className="aanand__icon__banner" src={placeCardIcon} alt="" />
                                    <h4>{t("homeBanner.events")}</h4>
                                </div>
                                <img className="coming__soon__tag" src={commingSoonTag} alt="" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};


// const SwapCrypto = () => {

//     const [selectedFromCrypto, setFromSelectedCrypto] = useState({
//         name: "Avalanche",
//         icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@bea1a9722a8c63169dcc06e86182bf2c55a76bbc/svg/color/avax.svg"
//     });

//     const cryptoOptions = [
//         { name: "Avalanche", icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@bea1a9722a8c63169dcc06e86182bf2c55a76bbc/svg/color/avax.svg" },
//         { name: "Bitcoin", icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@bea1a9722a8c63169dcc06e86182bf2c55a76bbc/svg/color/btc.svg" },
//         { name: "Ethereum", icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@bea1a9722a8c63169dcc06e86182bf2c55a76bbc/svg/color/eth.svg" },
//         { name: "Tron", icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@bea1a9722a8c63169dcc06e86182bf2c55a76bbc/svg/color/trx.svg" },
//         { name: "Tether", icon: "https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@bea1a9722a8c63169dcc06e86182bf2c55a76bbc/svg/color/usdt.svg" }
//     ];

//     return (
//         <div className="tab-content p-3 border bg-light" id="nav-tabContent">
//             <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab ">
//                 <div className="you__send__bx__frm mb-0">
//                     <div className="from__txt__bx">
//                         <p>From</p>
//                         <div className="select__bx">
//                             <div className="dropdown cust__drp__coin">
//                                 <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
//                                     {selectedFromCrypto.name}
//                                 </button>
//                                 <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
//                                     {cryptoOptions.map((crypto, index) => (
//                                         <li key={index}>
//                                             <button
//                                                 className="dropdown-item"
//                                                 onClick={() => setFromSelectedCrypto(crypto)}
//                                             >
//                                                 <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
//                                                 {crypto.name}
//                                             </button>
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="d-flex">
//                         <div className="select__bx">
//                             <div className="dropdown cust__drp__coin">
//                                 <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
//                                     WETH
//                                 </button>
//                                 <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
//                                     {cryptoOptions.map((crypto, index) => (
//                                         <li key={index}>
//                                             <button
//                                                 className="dropdown-item"
//                                                 onClick={() => setFromSelectedCrypto(crypto)}
//                                             >
//                                                 <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
//                                                 {crypto.name}
//                                             </button>
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                         <div className="txt__field__bx">
//                             <input type="text" className="form-control bg-transparent border-0 color__white text-end p-0" placeholder="0.01" value="0.01" />
//                         </div>

//                     </div>
//                     <div className="max__bx__container">
//                         <p className="max__txt__swap">Max</p>
//                         <p className="max__amount__txt">$2611.20 </p>
//                     </div>
//                 </div>
//                 <div className="swap__icon__bx">
//                     <img src={sortIcon} alt="" />
//                 </div>
//                 <div className="you__send__bx__frm">
//                     <div className="from__txt__bx">
//                         <p>To</p>
//                         <div className="select__bx">
//                             <div className="dropdown cust__drp__coin">
//                                 <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
//                                     BNB Chain
//                                 </button>
//                                 <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
//                                     {cryptoOptions.map((crypto, index) => (
//                                         <li key={index}>
//                                             <button
//                                                 className="dropdown-item"
//                                                 onClick={() => setFromSelectedCrypto(crypto)}
//                                             >
//                                                 <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
//                                                 {crypto.name}
//                                             </button>
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="d-flex">
//                         <div className="select__bx">
//                             <div className="dropdown cust__drp__coin">
//                                 <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
//                                     USDT
//                                 </button>
//                                 <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
//                                     {cryptoOptions.map((crypto, index) => (
//                                         <li key={index}>
//                                             <button
//                                                 className="dropdown-item"
//                                                 onClick={() => setFromSelectedCrypto(crypto)}
//                                             >
//                                                 <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
//                                                 {crypto.name}
//                                             </button>
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                         <div className="txt__field__bx">
//                             <input type="text" className="form-control bg-transparent border-0 color__white text-end p-0" placeholder="2610.39" value="2610.39" />
//                         </div>

//                     </div>
//                     <div className="max__bx__container">
//                         <p className="max__txt__swap">Max</p>
//                         <p className="max__amount__txt">$2611.39 </p>
//                     </div>
//                     <div className="add__rec__add__btn__bx">
//                         <button className="btn btn__addrecepient__add">Add recipient address</button>
//                     </div>
//                 </div>
//                 <div className="from__txt__bx">
//                     <p>1 WETH.e = 0.00000 USDTH($-)</p>
//                     <p>FEE:- </p>
//                 </div>
//                 <div className="btn__swap__bx__container">
//                     <button className="btn btn__swap__bx">Swap</button>
//                 </div>
//             </div>
//         </div>
//     )
// }
const SwapCrypto = () => {
    const [selectedFromCrypto, setSelectedFromCrypto] = useState(NetworkList?.[0]);

    const [selectedToCrypto, setSelectedToCrypto] = useState(NetworkList?.[1]);

    const [selectedFromToken, setSelectedFromToken] = useState(selectedFromCrypto?.tokenList?.[0]);
    const [selectedToToken, setSelectedToToken] = useState(selectedToCrypto?.tokenList?.[0]);
    const [fromTokenAmount, setFromTokenAmount] = useState('');
    const [fromUsdAmount, setFromUsdAmount] = useState(0);
    const [toTokenAmount, setToTokenAmount] = useState('');
    const [toUsdAmount, setToUsdAmount] = useState(0);

    const cryptoOptions = NetworkList

    // Handlers for dropdown selections
    const handleFromCryptoChange = (crypto) => {
        console.log("🚀 ~ handleFromCryptoChange ~ crypto:", crypto)
        setSelectedFromCrypto(crypto);
    };

    const handleToCryptoChange = (crypto) => {
        setSelectedToCrypto(crypto);
    };

    const handleFromTokenChange = (token) => {
        setSelectedFromToken(token);
    };

    const handleToTokenChange = (token) => {
        setSelectedToToken(token);
    };


    return (
        <div className="tab-content p-3 border bg-light" id="nav-tabContent">
            <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab ">
                {/* From Section */}
                <div className="you__send__bx__frm mb-0">
                    <div className="from__txt__bx">
                        <p>From</p>
                        <div className="select__bx">
                            <div className="dropdown cust__drp__coin">
                                <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedFromCrypto.name}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                                    {cryptoOptions.map((crypto, index) => (
                                        <li key={index}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleFromCryptoChange(crypto)}
                                            >
                                                <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
                                                {crypto.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="select__bx">
                            <div className="dropdown cust__drp__coin">
                                <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedFromToken?.tokenSymbol}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                                    {selectedFromCrypto?.tokenList?.map((crypto, index) => (
                                        <li key={index}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleFromTokenChange(crypto)}
                                            >
                                                <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
                                                {crypto.tokenSymbol}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="txt__field__bx">
                            <input type="text" className="form-control bg-transparent border-0 color__white text-end p-0" placeholder="0.01" onChange={(event) => setFromTokenAmount(event.target.value)} value={fromTokenAmount} />
                        </div>

                    </div>
                    <div className="max__bx__container">
                        <p className="max__txt__swap">Max</p>
                        <p className="max__amount__txt">${truncateNumber(fromUsdAmount)} </p>
                    </div>
                </div>

                {/* Swap Icon */}
                <div className="swap__icon__bx">
                    <img src={sortIcon} alt="" />
                </div>

                {/* To Section */}
                <div className="you__send__bx__frm">
                    <div className="from__txt__bx">
                        <p>To</p>
                        <div className="select__bx">
                            <div className="dropdown cust__drp__coin">
                                <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedToCrypto.name}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                                    {cryptoOptions?.map((crypto, index) => (
                                        <li key={index}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleToCryptoChange(crypto)}
                                            >
                                                <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
                                                {crypto.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="select__bx">
                            <div className="dropdown cust__drp__coin">
                                <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedToToken?.tokenSymbol}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                                    {selectedToCrypto?.tokenList?.map((crypto, index) => (
                                        <li key={index}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleToTokenChange(crypto)}
                                            >
                                                <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
                                                {crypto.tokenSymbol}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="txt__field__bx">
                            <input type="text" className="form-control bg-transparent border-0 color__white text-end p-0" placeholder="0.00" value={toTokenAmount} />
                        </div>

                    </div>
                    <div className="max__bx__container">
                        <p className="max__txt__swap">Max</p>
                        <p className="max__amount__txt">${truncateNumber(toUsdAmount)} </p>
                    </div>
                    <div className="add__rec__add__btn__bx">
                        <button className="btn btn__addrecepient__add">Add recipient address</button>
                    </div>
                </div>
                <div className="from__txt__bx">
                    <p>1 WETH.e = 0.00000 USDTH($-)</p>
                    <p>FEE:- </p>
                </div>
                <div className="btn__swap__bx__container">
                    <button className="btn btn__swap__bx">Swap</button>
                </div>
            </div>

        </div>
    )
}

const BuyAnd = () => {
    const [selectedFromCrypto, setSelectedFromCrypto] = useState(NetworkList?.[0]);

    const [selectedToCrypto, setSelectedToCrypto] = useState(NetworkList?.[1]);

    const [selectedFromToken, setSelectedFromToken] = useState(selectedFromCrypto?.tokenList?.[0]);
    const [selectedToToken, setSelectedToToken] = useState(selectedToCrypto?.tokenList?.[0]);
    const [fromTokenAmount, setFromTokenAmount] = useState('');
    const [fromUsdAmount, setFromUsdAmount] = useState(0);
    const [toTokenAmount, setToTokenAmount] = useState('');
    const [toUsdAmount, setToUsdAmount] = useState(0);

    const cryptoOptions = NetworkList

    // Handlers for dropdown selections
    const handleFromCryptoChange = (crypto) => {
        console.log("🚀 ~ handleFromCryptoChange ~ crypto:", crypto)
        setSelectedFromCrypto(crypto);
    };

    const handleToCryptoChange = (crypto) => {
        setSelectedToCrypto(crypto);
    };

    const handleFromTokenChange = (token) => {
        setSelectedFromToken(token);
    };

    const handleToTokenChange = (token) => {
        setSelectedToToken(token);
    };


    return (
        <div className="tab-content p-3 border bg-light" id="nav-tabContent">
            <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab ">
                {/* From Section */}
                <div className="you__send__bx__frm mb-0">
                    <div className="from__txt__bx">
                        <p>From</p>
                        <div className="select__bx">
                            <div className="dropdown cust__drp__coin">
                                <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedFromCrypto.name}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                                    {cryptoOptions.map((crypto, index) => (
                                        <li key={index}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleFromCryptoChange(crypto)}
                                            >
                                                <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
                                                {crypto.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="select__bx">
                            <div className="dropdown cust__drp__coin">
                                <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedFromToken?.tokenSymbol}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                                    {selectedFromCrypto?.tokenList?.map((crypto, index) => (
                                        <li key={index}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleFromTokenChange(crypto)}
                                            >
                                                <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
                                                {crypto.tokenSymbol}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="txt__field__bx">
                            <input type="text" className="form-control bg-transparent border-0 color__white text-end p-0 " autoComplete="off" placeholder="0.01" onChange={(event) => setFromTokenAmount(event.target.value)} value={fromTokenAmount || ''} />
                        </div>

                    </div>
                    <div className="max__bx__container">
                        <p className="max__txt__swap">Max</p>
                        <p className="max__amount__txt">${truncateNumber(fromUsdAmount)} </p>
                    </div>
                </div>

                {/* Swap Icon */}
                <div className="swap__icon__bx">
                    <img src={sortIcon} alt="" />
                </div>

                {/* To Section */}
                <div className="you__send__bx__frm">
                    <div className="from__txt__bx">
                        <p>To</p>
                        <div className="select__bx">
                            <div className="dropdown cust__drp__coin">
                                <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedToCrypto.name}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                                    {cryptoOptions?.map((crypto, index) => (
                                        <li key={index}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleToCryptoChange(crypto)}
                                            >
                                                <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
                                                {crypto.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="select__bx">
                            <div className="dropdown cust__drp__coin">
                                <button className="btn btn-trasprant dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedToToken?.tokenSymbol}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
                                    {selectedToCrypto?.tokenList?.map((crypto, index) => (
                                        <li key={index}>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleToTokenChange(crypto)}
                                            >
                                                <img className="crypto-icon me-2" src={crypto.icon} alt={crypto.name} width="20" />
                                                {crypto.tokenSymbol}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="txt__field__bx">
                            <input type="text" className="form-control bg-transparent border-0 color__white text-end p-0" placeholder="0.00" value={toTokenAmount} />
                        </div>

                    </div>
                    <div className="max__bx__container">
                        <p className="max__txt__swap">Max</p>
                        <p className="max__amount__txt">${truncateNumber(toUsdAmount)} </p>
                    </div>
                    <div className="add__rec__add__btn__bx">
                        <input className="btn btn__addrecepient__add" placeholder='Add recipient address' />
                    </div>
                </div>
                <div className="from__txt__bx">
                    <p>1 WETH.e = 0.00000 USDTH($-)</p>
                    <p>FEE:- </p>
                </div>
                <div className="btn__swap__bx__container">
                    <button className="btn btn__swap__bx">Swap</button>
                </div>
            </div>
        </div>
    )
}

const SwapSection = () => {
    const [selectedTab, setSelectedTab] = useState('swap');
    const { t } = useTranslation();

    return (
        <section className="swap__section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="digital__effert__head">
                            <h3>{t("swapSection.title")}</h3>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="swap__container__frm__bx">
                            <nav>
                                <div className="nav nav-tabs mb-3" role="tablist">
                                    <button
                                        className={`nav-link ${selectedTab === 'swap' ? 'active' : ''}`}
                                        onClick={() => setSelectedTab('swap')}
                                        type="button"
                                        role="tab"
                                        aria-selected={selectedTab === 'swap'}
                                    >
                                        Swap Crypto
                                    </button>

                                    <button
                                        className={`nav-link ${selectedTab === 'buy' ? 'active' : ''}`}
                                        onClick={() => setSelectedTab('buy')}
                                        type="button"
                                        role="tab"
                                        aria-selected={selectedTab === 'buy'}
                                    >
                                        Buy AND
                                    </button>
                                </div>
                            </nav>

                            {selectedTab === 'swap' ? <SwapCrypto /> : <BuyAnd />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

// const AboutToken = () => {
//     const { t } = useTranslation();

//     return (
//         <section className="token__section">
//             <div className="container">
//                 <div className="row mb-5">
//                     <div className="col-md-5">
//                         <div className="token__image__bx">
//                             <img className="w-100" src={tokenChart} alt="Token Chart" />
//                         </div>
//                     </div>
//                     <div className="col-md-7">
//                         <div className="token__table__container">
//                             <h3 className="mb-3">{t("aboutToken.title")}</h3>
//                             <div className="token__text__name">
//                                 <p>{t("aboutToken.tokenName")} :</p>
//                                 <h6>AANAND</h6>
//                             </div>
//                             <div className="token__text__name">
//                                 <p>{t("aboutToken.symbol")} :</p>
//                                 <h6>AND</h6>
//                             </div>
//                             <div className="token__text__name">
//                                 <p>{t("aboutToken.protocol")} :</p>
//                                 <h6>ERC20</h6>
//                             </div>
//                             <div className="token__text__name">
//                                 <p>{t("aboutToken.totalSupply")} :</p>
//                                 <h6>1,000,000,000 AND</h6>
//                             </div>
//                             <div className="token__text__name">
//                                 <p>{t("aboutToken.type")} :</p>
//                                 <h6>{t("aboutToken.utilityToken")}</h6>
//                             </div>
//                             <div className="token__copy__bx">
//                                 <p>{t("aboutToken.contract")}: 0x1b4A74e55b9C...3991E08BFb42f829
//                                     <button className="btn">
//                                         <img src={copyIcon} alt="copy" />
//                                     </button>
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// };


const ProjectSection = () => {
    const { t } = useTranslation();

    return (
        <section className="project_prob_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="problem__project__head">
                            <h2>{t("projectSection.title")}</h2>
                            <img className="w-100" src="img/eventimage.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="problem__proj__solved__bx">
                            <p className="icon">+</p>
                            <h6>{t("projectSection.globalKpop.title")}</h6>
                            <p>{t("projectSection.globalKpop.description")}</p>
                        </div>
                        <div className="problem__proj__solved__bx">
                            <p className="icon">+</p>
                            <h6>{t("projectSection.andProject.title")}</h6>
                            <p>{t("projectSection.andProject.description")}</p>
                        </div>
                        <div className="problem__proj__solved__bx">
                            <p className="icon">+</p>
                            <h6>{t("projectSection.connectingFandoms.title")}</h6>
                            <p>{t("projectSection.connectingFandoms.description")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const PlatformFeatures = () => {
    const { t } = useTranslation();

    return (
        <section className="platform__features__section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="platform__head">
                            <h3>{t("platformFeatures.title")}</h3>
                            <p>{t("platformFeatures.subtitle")}</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="platform__f__bx">
                            <img src={nowBlogs} alt="" />
                            <h4>{t("platformFeatures.features.corePlatform.title")}</h4>
                            <p>{t("platformFeatures.features.corePlatform.description")}</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="platform__f__bx">
                            <img src={nowWallet} alt="" />
                            <h4>{t("platformFeatures.features.paymentIntegration.title")}</h4>
                            <p>{t("platformFeatures.features.paymentIntegration.description")}</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="platform__f__bx">
                            <img src={nowTracker} alt="" />
                            <h4>{t("platformFeatures.features.performanceOptimization.title")}</h4>
                            <p>{t("platformFeatures.features.performanceOptimization.description")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const BusinessSolution = () => {
    const { t } = useTranslation();

    return (
        <>
            {/* Business Solution Section */}
            <section className="digital__effert__secrion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="digital__effert__head">
                                <h3>{t("businessSolution.title")}</h3>
                                <p>{t("businessSolution.subtitle")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {["integratedPlatform", "securePayments", "robustArchitecture"].map((key, index) => (
                            <div className="col-md-4 d-flex mb-4" key={index}>
                                <div className="steps__bx__container h-100 d-flex flex-column">
                                    <div className="steps__digital__img">
                                        <img src={t(`businessSolution.features.${key}.image`)} alt="" />
                                    </div>
                                    <div className="steps__digital__txt mt-auto">
                                        <h6>{t(`businessSolution.features.${key}.title`)}</h6>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Global Market Section */}
            <section className="global__market__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="digital__effert__head">
                                <h3>{t("globalMarket.title")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Global Market Map */}
            <section>
                <div className="global__market__map">
                    <img className="w-100" src={globalMarket} alt="" />
                </div>
            </section>
        </>
    );
};


const Home = () => {

    return (
        <div>
            <NewHeader />
            <HomeBanner />
            {/* <SwapSection /> */}
            <AboutToken />
            <ProjectSection />
            <BusinessSolution />
            <PlatformFeatures />
            <ContactUs />
            <NewFooter />
        </div>
    )
}

export default Home