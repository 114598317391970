import React from 'react'
import { NetworkList } from '../utils/networkList'
import CloseIcon from '../assets/images/close.svg'
import { TOKENS } from '../assets/constant'

export const NetworkTokenModal = ({ _handleClose, _tokenList, _activeToken, _activeNetwork }) => {

    const handleSelectNetwork = (_network) => {
        const _tokenList = _network?.tokenList || [];
        let _token = _tokenList[0];

        if (_activeToken?.tokenSymbol === TOKENS[0]) {
            _token = _tokenList[1] || _token;
        } else if (_activeToken?.tokenSymbol === TOKENS[1]) {
            _token = _tokenList[2] || _token;
        }

        _handleClose(_network, _token, false)
    }

    const handleSelectToken = (_token) => {
        _handleClose(_activeNetwork, _token, true)
    }

    return (
        <div className='overlay-modals left-side'>
            <div className='networkmodal'>
                <div className='networkmodal-header'>
                    <div></div>
                    <button
                        onClick={() => {
                            _handleClose(_activeNetwork, _activeToken, true)
                        }}
                        className='closebtns'>
                        <img src={CloseIcon} alt='close-icon' />
                    </button>
                </div>
                <div className='networkmodal-body'>
                    <div className='row-class'>
                        <h4 className='networktitle'>Networks</h4>
                        <div className='networklist-ul'>
                            {
                                NetworkList?.map((_network, _index) => {
                                    const isActive = _network?.symbol === _activeNetwork?.symbol;

                                    return (
                                        <div
                                            key={_index}
                                            onClick={() => handleSelectNetwork(_network)}
                                            className={"networklist " + (isActive ? 'active' : '')}
                                        >
                                            <img src={_network?.icon} alt="polygon-icon" />
                                            {_network?.name}
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>

                    <div className='row-class' style={{ marginTop: 20 }}>
                        <h4 className='networktitle'><img src={_activeNetwork?.icon} alt='active-network' /> {_activeNetwork?.name} Chain tokens</h4>
                        <div className='networklist-ul'>
                            {
                                _tokenList?.map((_token, _index) => {
                                    const isActive = _activeToken?.tokenSymbol === _token?.tokenSymbol
                                    return (
                                        <div key={_index} onClick={() => handleSelectToken(_token)} className={"networklist " + (isActive ? 'active' : '')}>
                                            <img src={_token?.icon} alt="polygon-icon" />  {_token?.tokenSymbol}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
