import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend) // Load translations from JSON files
  .use(LanguageDetector) // Detect language from browser or querystring
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    fallbackLng: 'en', // Default language
    debug: true, // Enable debug mode
    interpolation: {
      escapeValue: false // React already escapes values to prevent XSS
    },
    backend: {
      loadPath: './locales/{{lng}}/translation.json' // Path to translations
    },
  });

export default i18n;
