import React from 'react'
import CryptoWalletBanner from '../assets/images/home/crypto-wallet-banner.png'

import NewHeader from '../components/NewHeader';
import NewFooter from '../components/NewFooter';
import { Link } from 'react-router-dom';
import commingSoonTag from '../assets/images/home/coming-soon-tag.png'
import { useTranslation } from 'react-i18next';

const AndAndroidWallet = () => {

    const { t } = useTranslation();

    return (
        <div>
            <NewHeader />



            {/* Hero Section */}
            <section className="product__banner__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="hero__banner__head">
                                <h1>{t('androidWallet.title')}</h1>
                                <p>{t('androidWallet.description')}</p>
                                <div className="download__app__bx">
                                    <Link href="#" target="_blank">
                                        {t('androidWallet.download')}
                                        <img className="coming__soon__tag__footer" src={commingSoonTag} alt={t.comingSoon} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="prod__banner__img__bx">
                                <img className="w-100" src={CryptoWalletBanner} alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="platform__features__section k-pop-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="platform__head">
                                <h3>{t('androidWallet.featuresTitle')}</h3>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx text-center">
                                <h4>{t('androidWallet.features1')}</h4>
                                <p>{t('androidWallet.features1Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx text-center">
                                <h4>{t('androidWallet.features2')}</h4>
                                <p>{t('androidWallet.features2Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx text-center">
                                <h4>{t('androidWallet.features3')}</h4>
                                <p>{t('androidWallet.features3Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx text-center">
                                <h4>{t('androidWallet.features4')}</h4>
                                <p>{t('androidWallet.features4Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx text-center">
                                <h4>{t('androidWallet.features5')}</h4>
                                <p>{t('androidWallet.features5Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx text-center">
                                <h4>{t('androidWallet.features6')}</h4>
                                <p>{t('androidWallet.features6Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx text-center">
                                <h4>{t('androidWallet.features7')}</h4>
                                <p>{t('androidWallet.features7Description')}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="digital__effert__secrion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="digital__effert__head">
                                <h3>{t('androidWallet.whyTitle')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 d-flex mb-4">
                            <div className="steps__bx__container h-100 d-flex flex-column">
                                <div className="steps__digital__txt mt-auto">
                                    <h6>{t('androidWallet.why1')}</h6>
                                    <p>{t('androidWallet.why1Description')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex mb-4">
                            <div className="steps__bx__container h-100 d-flex flex-column">
                                <div className="steps__digital__txt mt-auto">
                                    <h6>{t('androidWallet.why2')}</h6>
                                    <p>{t('androidWallet.why2Description')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex mb-4">
                            <div className="steps__bx__container h-100 d-flex flex-column">
                                <div className="steps__digital__txt mt-auto">
                                    <h6>{t('androidWallet.why3')}</h6>
                                    <p>{t('androidWallet.why3Description')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex mb-4">
                            <div className="steps__bx__container h-100 d-flex flex-column">
                                <div className="steps__digital__txt mt-auto">
                                    <h6>{t('androidWallet.why4')}</h6>
                                    <p>{t('androidWallet.why4Description')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="wallet__keys__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wallet__keys__crypto__bx">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="crypto__wallet__txt text-center">
                                            <h3>{t('androidWallet.takeControl')}</h3>
                                            <p className="mb-3">{t('androidWallet.takeControlDesc')}</p>
                                            <p>{t('androidWallet.takeControlSub')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <NewFooter />

        </div>
    )
}

export default AndAndroidWallet
