import React from 'react';
// import "./i18n"
import ReactDOM from 'react-dom/client';
// import './style.css';
import App from './App';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <App />
    <ToastContainer />
  </React.Fragment>

);
