import React, { createContext, useEffect, useState } from 'react';
import MultiWalletConnector from 'multi-wallet-connector';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { isAddress } from 'ethers';

import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Header from './components/Header';

import Home from './pages/home';
import Product from './pages/product';
import DashboardComponent from './pages/dashboard';
import Swap from './pages/swap';
import Transactions from './pages/transactions';
import Token from './pages/token';

import WalletDownload from './pages/walletDownload';
import ContactUs from './pages/contactUs';

import AndAndroidWallet from './pages/andAndroidWallet';
import AndChromeWallet from './pages/andChromeWallet';
import AndEventPlatform from './pages/andEventPlatform';

export const LangContext = createContext();

const useWallet = () => {
  const [walletAddress, setWalletAddress] = useState('');

  const connectWallet = async () => {
    try {
      const wallet = await MultiWalletConnector.connectWallet('Metamask', 'Connect Wallet');
      if (wallet?.status) {
        setWalletAddress(wallet.walletAddress);
        localStorage.setItem('walletAddress', wallet.walletAddress);
      }
    } catch (error) {
      console.error('Wallet connection failed:', error);
    }
  };

  const disconnectWallet = () => {
    MultiWalletConnector.disconnectWallet();
    setWalletAddress('');
    localStorage.removeItem('walletAddress');
  };

  const handleWallet = () => {
    walletAddress ? disconnectWallet() : connectWallet();
  };

  useEffect(() => {
    const savedWallet = localStorage.getItem('walletAddress');
    if (isAddress(savedWallet)) {
      setWalletAddress(savedWallet);
      MultiWalletConnector.setActiveWallet(savedWallet, 'Metamask');
    }
  }, []);

  return { walletAddress, handleWallet };
};

// Layout Component
const DashboardLayout = ({ children, walletAddress, handleWallet }) => (
  <div className="dashboard-page">
    <Sidebar walletAddress={walletAddress} handleConnect={handleWallet} />
    <div className="content-page">
      <Header walletAddress={walletAddress} handleConnect={handleWallet} />
      {children}
      <Footer />
    </div>
  </div>
);

// Main App Component
const App = () => {
  const { walletAddress, handleWallet } = useWallet();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/token" element={<Token />} />
        <Route path="/wallet-download" element={<WalletDownload />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/and-android-wallet" element={<AndAndroidWallet />} />
        <Route path="/and-chrome-wallet" element={<AndChromeWallet />} />
        <Route path="/and-event-platform" element={<AndEventPlatform />} />

        <Route
          path="/dashboard"
          element={
            <DashboardLayout walletAddress={walletAddress} handleWallet={handleWallet}>
              <DashboardComponent walletAddress={walletAddress} />
            </DashboardLayout>
          }
        />
        <Route
          path="/buy-and"
          element={
            <DashboardLayout walletAddress={walletAddress} handleWallet={handleWallet}>
              <Swap walletAddress={walletAddress} />
            </DashboardLayout>
          }
        />
        <Route
          path="/transactions"
          element={
            <DashboardLayout walletAddress={walletAddress} handleWallet={handleWallet}>
              <Transactions walletAddress={walletAddress} />
            </DashboardLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
