export function getData(url) {
    let authToken = localStorage?.getItem("authToken");

    if (authToken) {
        var headers = {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + authToken,
        };
    }

    return fetch(`${url}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: headers,
    })
        .then((response) => { return response.json() })
        // .then(async (data) => {
        //     return data;
        // })
        .catch((error) => {
            return error;
        });
}

export function postData(url, data) {
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
    };
    let authToken = localStorage?.getItem("authToken");

    if (authToken) {
        headers = {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + authToken,
        };
    }

    return fetch(`${url}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    })
        .then((response) => { return response.json() })
        // .then((data) => {
        //     return data;
        // })
        .catch((error) => {
            return error;
        });
}
