import React from 'react'
import emailIcon from '../assets/images/home/email-blue_icon.svg'
import locationIcon from '../assets/images/home/location-blue_icon.svg'

import NewHeader from '../components/NewHeader';
import NewFooter from '../components/NewFooter';
import { useTranslation } from 'react-i18next';


const ContactUs = () => {
    const { t } = useTranslation();

    return (
        <section className="get__touch__section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="get__in__touch__content">
                            <h3>{t("contactUs.title")}</h3>
                            <p>{t("contactUs.description")}</p>

                            <div className="get__in__touch__email__bx">
                                <div className="get__email__bx">
                                    <img src={emailIcon} alt="Email" />
                                    <div className="email__bx">
                                        <h6>{t("contactUs.email")}</h6>
                                        <a href="mailto:info@aanands.com">info@aanands.com</a>
                                    </div>
                                </div>
                                <div className="get__email__bx">
                                    <img src={locationIcon} alt="Location" />
                                    <div className="email__bx">
                                        <h6>{t("contactUs.location")}</h6>
                                        <a href="#">{t("contactUs.seoul")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form__footer__bx">
                            <form className="cust_-footer__form">
                                <div className="mb-3">
                                    <label htmlFor="Name" className="form-label">{t("contactUs.name")}</label>
                                    <input type="text" className="form-control" id="Name" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Email" className="form-label">{t("contactUs.email")}</label>
                                    <input type="text" className="form-control" id="Email" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Subject" className="form-label">{t("contactUs.subject")}</label>
                                    <textarea type="text" className="form-control" id="Subject"></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Message" className="form-label">{t("contactUs.message")}</label>
                                    <textarea type="text" className="form-control" id="Message" rows="3"></textarea>
                                </div>
                                <div className="mb-3">
                                    <button className="btn btn__submit__frm">{t("contactUs.submit")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

function contactUs() {
  return (
    <div>
        <NewHeader />
        <ContactUs/>
        {/* <section className="get__touch__section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="get__in__touch__content">
                            <h3>Get in Touch</h3>
                            <p>Join our revolution in K-pop platform technology. Connect with us to learn more about AANAND's innovative solutions</p>

                            <div className="get__in__touch__email__bx">
                                <div className="get__email__bx">
                                    <img src={emailIcon} alt="Email" />
                                    <div className="email__bx">
                                        <h6>Email</h6>
                                        <a href="mailto:info@aanands.com">info@aanands.com</a>
                                    </div>
                                </div>
                                <div className="get__email__bx">
                                    <img src={locationIcon} alt="Email" />
                                    <div className="email__bx">
                                        <h6>Location</h6>
                                        <a href="#">Seoul, South Korea</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form__footer__bx">
                            <form className="cust_-footer__form">
                                <div className="mb-3">
                                    <label for="Name" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="Name" />
                                </div>
                                <div className="mb-3">
                                    <label for="Email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="Email" />
                                </div>
                                <div className="mb-3">
                                    <label for="Subject" className="form-label" rows="3">Subject</label>
                                    <textarea type="text" className="form-control" id="Subject"></textarea>
                                </div>
                                <div className="mb-3">
                                    <label for="Message" className="form-label">Message</label>
                                    <textarea type="text" className="form-control" id="Message" rows="3"></textarea>
                                </div>
                                <div className="mb-3">
                                    <button className="btn btn__submit__frm">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <NewFooter />
    </div>
  )
}

export default contactUs
