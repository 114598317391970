import React from 'react'

import NewHeader from '../components/NewHeader';
import NewFooter from '../components/NewFooter';
import { Link } from 'react-router-dom';
import commingSoonTag from '../assets/images/home/coming-soon-tag.png'
import EventBookingPlatform from '../assets/images/home/event-booking-platform.png'
import SecureTransactions from '../assets/images/home/secure-transactions.png'
import UserFriendly from '../assets/images/home/user-friendly.png'
import GlobalAccess from '../assets/images/home/global-access.png'
import Nfts from '../assets/images/home/nfts.png'
import TokenChart from '../assets/images/home/token-chart.png'
import CopyIcon from '../assets/images/home/copy_icon.png'
import StepDigital1 from '../assets/images/home/digital-1.png'
import StepDigital2 from '../assets/images/home/digital-2.png'
import StepDigital3 from '../assets/images/home/digital-3.png'

import PopAccess from '../assets/images/home/pop-access.png'
import PopGlobal from '../assets/images/home/pop-global.png'
import PopCreditCard from '../assets/images/home/pop-credit-card.png'
import PopPublic from '../assets/images/home/pop-public.png'
import EtheriumImagewallet from '../assets/images/home/etherium-imagewallet.png'
import AboutToken from '../components/AboutToken';
import { useTranslation } from 'react-i18next';


const AndEventPlatform = () => {
    const { t } = useTranslation();

    return (
        <div>
            <NewHeader />
            <section className="product__banner__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="hero__banner__head">
                                {/* <h1>Aanand Event Booking Platform: Revolutionizing K-POP and Global Event Experiences</h1> */}
                                <h1>{t('eventPage.title')}</h1>
                                <div className="download__app__bx">
                                    <a href="#" target="_blank" className="gradient__bg">{t('eventPage.eventPage')}
                                        {/* <!-- <img src="img/chrome__icon.svg" alt="">  --> */}
                                        <img className="coming__soon__tag__footer" src={commingSoonTag} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="prod__banner__img__bx">
                                <img className="w-100" src={EventBookingPlatform} alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="browser__extension__section">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="digital__effert__head">
                                <h3>{t('eventPage.revolution')}</h3>
                                <p className="mb-3">{t('eventPage.description')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="browser__extension__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="multi__chain__experience__container">
                                <div className="digital__effert__head">
                                    <h3>{t('eventPage.transfer')}</h3>
                                    <p>{t('eventPage.transferDescription')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="buy__crypto__section__bx">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                <h4>{t('eventPage.secure1')}</h4>
                                <p>{t('eventPage.secure1Description')}</p>
                                <img src={SecureTransactions} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                <h4>{t('eventPage.secure2')}</h4>
                                <p>{t('eventPage.secure2Description')}</p>
                                <img src={UserFriendly} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                <h4>{t('eventPage.secure3')}</h4>
                                <p>{t('eventPage.secure3Description')}</p>
                                <img src={GlobalAccess} alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-3">
                            <div className="buy__crypto__bx__container">
                                <h4>{t('eventPage.secure4')}</h4>
                                <p>{t('eventPage.secure4Description')}</p>
                                <img src={Nfts} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="token__section">
          <div className="container">
              <div className="row mb-5">
                  <div className="col-md-5">
                      <div className="token__image__bx">
                          <img className="w-100" src={TokenChart} alt="Token Chart" />
                      </div>
                  </div>
                  <div className="col-md-7">
                      <div className="token__table__container">
                          <h3 className="mb-3">About Token</h3>
                          <div className="token__text__name">
                              <p>Token Name : </p>
                              <h6>AANAND</h6>
                          </div>
                          <div className="token__text__name">
                              <p>Symbol : </p>
                              <h6>AND</h6>
                          </div>
                          <div className="token__text__name">
                              <p>Protocol : </p>
                              <h6>ERC20</h6>
                          </div>
                          <div className="token__text__name">
                              <p>Total Supply : </p>
                              <h6>1,000,000,000 AND</h6>
                          </div>
                          <div className="token__text__name">
                              <p>Type : </p>
                              <h6>Utility Token</h6>
                          </div>
                          <div className="token__copy__bx">
                              <p>AND Contract: 0x1b4A74e55b9C...3991E08BFb42f829 <button className="btn"> <img src={CopyIcon} alt="copy" /> </button></p>
                          </div>
                          <div className="mt-3">
                              <p>The AND token powers the platform, making it the preferred payment method for booking K-POP and other global events</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section> */}
            <AboutToken />
            <section className="platform__features__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="platform__head">
                                <h3>{t('eventPage.problem')}</h3>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="platform__f__bx">
                                <h4>{t('eventPage.problem1')}</h4>
                                <p>{t('eventPage.problem1Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="platform__f__bx">
                                <h4>{t('eventPage.problem2')}</h4>
                                <p>{t('eventPage.problem2Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="platform__f__bx">
                                <h4>{t('eventPage.problem3')}</h4>
                                <p>{t('eventPage.problem3Description')}</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <section className="digital__effert__secrion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="digital__effert__head">
                                <h3>{t('eventPage.effortless')}</h3>
                                <p>{t('eventPage.effortlessDescription')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 d-flex mb-4">
                            <div className="steps__bx__container h-100 d-flex flex-column">
                                <div className="steps__digital__img">
                                    <img src={StepDigital1} alt="" />
                                </div>
                                <div className="steps__digital__txt mt-auto">
                                    <h6>{t('eventPage.effortlessStep1')}</h6>
                                    <p>{t('eventPage.effortlessStep1Text')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex mb-4">
                            <div className="steps__bx__container h-100 d-flex flex-column">
                                <div className="steps__digital__img">
                                    <img src={StepDigital2} alt="" />
                                </div>
                                <div className="steps__digital__txt mt-auto">
                                    <h6>{t('eventPage.effortlessStep2')}</h6>
                                    <p>{t('eventPage.effortlessStep2Text')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex mb-4">
                            <div className="steps__bx__container h-100 d-flex flex-column">
                                <div className="steps__digital__img">
                                    <img src={StepDigital3} alt="" />
                                </div>
                                <div className="steps__digital__txt mt-auto">
                                    <h6>{t('eventPage.effortlessStep3')}</h6>
                                    <p>{t('eventPage.effortlessStep3Text')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="platform__features__section k-pop-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="platform__head">
                                <h3>{t('eventPage.focus')}</h3>
                                <p>{t('eventPage.focusDescription')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx">
                                <img src={PopAccess} alt="Pop Access" />
                                <h4>{t('eventPage.focus1')}</h4>
                                <p>{t('eventPage.focus1Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx">
                                <img src={PopGlobal} alt="" />
                                <h4>{t('eventPage.focus2')}</h4>
                                <p>{t('eventPage.focus2Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx">
                                <img src={PopCreditCard} alt="" />

                                <h4>{t('eventPage.focus3')}</h4>
                                <p>{t('eventPage.focus3Description')}</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <div className="platform__f__bx">
                                <img src={PopPublic} alt="" />
                                <h4>{t('eventPage.focus4')}</h4>
                                <p>{t('eventPage.focus4Description')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wallet__keys__section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wallet__keys__crypto__bx">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="crypto__wallet__img">
                                            <img src={EtheriumImagewallet} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="crypto__wallet__txt">
                                            <h3>{t('eventPage.journey')}</h3>
                                            <p>{t('eventPage.journeyDescription')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <NewFooter />
        </div>
    )
}

export default AndEventPlatform
