
import anadaWhiteLogo from '../assets/images/home/aanand-logo-white.svg'
import twitter from '../assets/images/home/twitter-x.svg'
import telegram from '../assets/images/home/telegram.svg'
import linkedin from '../assets/images/home/linkedin.svg'
import medium from '../assets/images/home/medium.svg'
import instagram from '../assets/images/home/instagram.svg'
import youtube from '../assets/images/home/youtube.svg'
import reddit from '../assets/images/home/reddit.svg'

import chromeStore from '../assets/images/home/chrome__icon.svg'
import playStore from '../assets/images/home/playstore__icon.png'

import commingSoonTag from '../assets/images/home/coming-soon-tag.png'
import { useTranslation } from 'react-i18next'

const NewFooter = () => {
    const { t } = useTranslation();
    return (
        <footer class="footer__section">
            <div class="container">
                <div class="row mb-5">
                    <div class="col-md-3">
                        <div class="footer__logoz__bx">
                            <a class="navbar-brand" href="#"><img src={anadaWhiteLogo} alt="Aanand Logo" /></a>
                            <p>{t("footer.description")}</p>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div className="row">
                            {[
                                { title: "wallet", links: ["mobile_app", "browser_extension"] },
                                { title: "features", links: ["buy_and", "swaps", "nft"] },
                                { title: "support", links: ["telegram", "x", "contact_us"] },
                                { title: "about", links: ["about_us", "product"] }
                            ].map(({ title, links }) => (
                                <div className="col-md-3" key={title}>
                                    <div className="footer__links__bx">
                                        <h6>{t(`footer.${title}`)}</h6>
                                        <ul>
                                            {links.map(link => (
                                                <li key={link}>
                                                    <a href="#">{t(`footer.${link}`)}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="footer__second__bx">
                            <h6>{t("footer.download_wallet")}</h6>
                            <p>{t("footer.secure_crypto_wallet")}</p>
                            <div class="download__app__bx">
                                <a href="#" target="_blank">{t("footer.download_extension")}<img src={chromeStore} alt="" />
                                    <img class="coming__soon__tag__footer" src={commingSoonTag} alt="" />
                                </a>
                                <a href="#" target="_blank">  {t("footer.download_android")}<img src={playStore} alt="" />
                                    <img class="coming__soon__tag__footer" src={commingSoonTag} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="social__media__bx text-end">
                            <h6>{t("footer.stay_connected")}</h6>
                            <div class="socila__media__icon">
                                <ul>
                                    <li>
                                        <a href="https://x.com/and_aanand">
                                            <img src={twitter} alt="Twitter" /></a>
                                    </li>
                                    <li>
                                        <a href="https://t.me/AND_Official_Channel">
                                            <img src={telegram} alt="Telegram" /></a>
                                    </li>

                                    <li>
                                        <a href="https://medium.com/@AANAND_Official">
                                            <img src={medium} alt="Medium" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy__right__footer__container">
                    <p>© {new Date().getFullYear()} AANAND PVT. LTD. {t("footer.all_rights_reserved")}</p>
                </div>
            </div>
        </footer>)
}

export default NewFooter