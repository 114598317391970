import React from 'react'
import { Link } from 'react-router-dom'
import commingSoonTagImage from '../assets/images/home/coming-soon-tag.png'
import playStoreImage from '../assets/images/home/play-store.png'
import chromeImage from '../assets/images/home/chrome.svg'
import androidImage from '../assets/images/home/android.png'
import appStoreImage from '../assets/images/home/app-store.png'
import NewHeader from '../components/NewHeader';
import NewFooter from '../components/NewFooter'
import { useTranslation } from 'react-i18next'




const WalletDownload = () => {
    const { t } = useTranslation();
  
    return (
      <div>
        <NewHeader />
        <section className="wallet__section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="wallet__container__bx">
                  <div className="wallet__banner__bx">
                    <h1>{t("walletDownload.title")}</h1>
                    <p>{t("walletDownload.description")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section className="digital__effert__secrion wallet__download__section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-flex mb-4">
                <Link to="#" className="d-block w-100">
                  <div className="steps__bx__container h-100 d-flex flex-column">
                    <div className="steps__digital__img">
                      <img src={playStoreImage} alt="" />
                    </div>
                    <div className="steps__digital__txt mt-auto">
                      <h6>{t("walletDownload.androidTitle")}</h6>
                      <p>{t("walletDownload.androidDescription")}</p>
                    </div>
                    <img
                      className="coming__soon__app__download"
                      src={commingSoonTagImage}
                      alt=""
                    />
                  </div>
                </Link>
              </div>
              <div className="col-md-6 d-flex mb-4">
                <Link to="#" className="d-block w-100">
                  <div className="steps__bx__container h-100 d-flex flex-column">
                    <div className="steps__digital__img">
                      <img src={chromeImage} alt="" />
                    </div>
                    <div className="steps__digital__txt mt-auto">
                      <h6>{t("walletDownload.chromeTitle")}</h6>
                      <p>{t("walletDownload.chromeDescription")}</p>
                    </div>
                    <img
                      className="coming__soon__app__download"
                      src={commingSoonTagImage}
                      alt=""
                    />
                  </div>
                </Link>
              </div>
              <div className="col-md-6 d-flex mb-4">
                <Link to="#" className="d-block w-100">
                  <div className="steps__bx__container h-100 d-flex flex-column">
                    <div className="steps__digital__img">
                      <img src={androidImage} alt="" />
                    </div>
                    <div className="steps__digital__txt mt-auto">
                      <h6>{t("walletDownload.apkTitle")}</h6>
                      <p>{t("walletDownload.apkDescription")}</p>
                    </div>
                    <img
                      className="coming__soon__app__download"
                      src={commingSoonTagImage}
                      alt=""
                    />
                  </div>
                </Link>
              </div>
              <div className="col-md-6 d-flex mb-4">
                <Link to="#" className="d-block w-100">
                  <div className="steps__bx__container h-100 d-flex flex-column">
                    <div className="steps__digital__img">
                      <img src={appStoreImage} alt="" />
                    </div>
                    <div className="steps__digital__txt mt-auto">
                      <h6>{t("walletDownload.iosTitle")}</h6>
                      <p>{t("walletDownload.iosDescription")}</p>
                    </div>
                    <img
                      className="coming__soon__app__download"
                      src={commingSoonTagImage}
                      alt=""
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
  
        <NewFooter />
      </div>
    );
  };
  

export default WalletDownload

