import { getData, postData } from "./serviceWrapper";
import Config from "../assets/config";

const BASE_URL = Config?.baseURL

async function generateQuote(chainId, asset, amount) {
    let _response = await getData(`${BASE_URL}/purchaseToken/getQuote?chainId=${chainId}&asset=${asset}&amount=${amount}`);
    return _response;
}

async function getPurchaseTxList(page, limit, walletAddress) {
    console.log("🚀 ~ getPurchaseTxList ~ walletAddress:", walletAddress)
    let URL = `${BASE_URL}/purchaseToken/listing?page=${page}&limit=${limit}`;

    if (walletAddress) {
        URL += `&walletAddress=${walletAddress}`
    }
    let _response = await getData(URL);
    return _response;
}


async function processPurchaseToken(data) {
    let URL = `${BASE_URL}/purchaseToken/process`;

    let _response = await postData(URL, data);
    return _response;
}

async function processANDSend(data) {
    let URL = `${BASE_URL}/erc20Transfer/process`;

    let _response = await postData(URL, data);
    return _response;
}



export {
    generateQuote,
    getPurchaseTxList,
    processPurchaseToken,
    processANDSend
};