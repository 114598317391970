import Ethereum from "../assets/images/token/ethereum.svg"
import Arbitrum from "../assets/images/token/arbitrum.svg"
import Avalanche from "../assets/images/token/avalanche.svg"
import Matic from "../assets/images/token/matic.svg"
import Optimism from "../assets/images/token/optimism.svg"
import USDT from "../assets/images/token/usdt.svg"
import USDC from "../assets/images/token/usdc.svg"
import BNB from "../assets/images/token/binance.svg"
import ANDIcon from "../assets/images/and-icon-circle.svg"
import { ZeroAddress as ZERO_ADDRESS } from "ethers"
import { ANAND_TOKEN_ADDRESS } from "../assets/constant"

const TokenIcon = {
    ETH: Ethereum,
    AND: ANDIcon,
    BNB: BNB,
    MATIC: Matic,
    AVAX: Avalanche,
    OP: Optimism,
    ARB: Arbitrum,
    USDT: USDT,
    USDC: USDC,
    BSC: BNB,
    Binance: BNB,
    Ethereum: Ethereum,
    Polygon: Matic,
    Avalanche: Avalanche
}


const ChainName = {
    ETH: "Ethereum",
    BSC: "Binance",
    MATIC: "Polygon",
    AVAX: "Avalanche",
    OP: "Optimism",
    ARB: "Arbitrum"
}

const ChainSymbol = {
    ETH: "ETH",
    BSC: "BSC",
    MATIC: "MATIC",
    AVAX: "AVAX",
    OP: "OP",
    ARB: "ARB"
}

const ChainTokenSymbol = {
    ETH: "ETH",
    BSC: "BNB",
    MATIC: "MATIC",
    AVAX: "AVAX",
    OP: "ETH",
    ARB: "ETH"
}

const ChainIdList = {
    ETH: 1,
    BSC: 56,
    MATIC: 137,
    AVAX: 43114,
    OP: 10,
    ARB: 42161
}

const NetworkByChaindId = {
    1: "ETH",
    56: "BSC",
    137: "Polygon",
    43114: "AVAX",
    10: "OP",
    42161: "ARB"
}

const RPCList = {
    ETH: 'https://rpc.ankr.com/eth',
    BSC: 'https://bsc-dataseed1.ninicoin.io',
    MATIC: 'https://flashy-fragrant-diagram.matic.quiknode.pro/d3c0e5fab188019934559fdd7ebc05f93253181e/',
    AVAX: "https://api.avax.network/ext/bc/C/rpc",
    OP: "https://mainnet.optimism.io/",
    ARB: 'https://arb1.arbitrum.io/rpc'
}

const ExplorerList = {
    ETH: "https://etherscan.io",
    BSC: "https://bscscan.com",
    MATIC: "https://polygonscan.com",
    AVAX: "https://snowtrace.io",
    OP: "https://optimistic.etherscan.io/",
    ARB: "https://arbiscan.io/"
}


const TokenAddressList = {
    ETH: {
        USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
    },
    BSC: {
        USDT: '0x55d398326f99059fF775485246999027B3197955',
        USDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'
    },
    MATIC: {
        AND: ANAND_TOKEN_ADDRESS,
        USDT: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        USDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
    },
    AVAX: {
        USDT: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        USDC: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e'
    },
    OP: {
        USDT: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        USDC: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607'
    },
    ARB: {
        USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        USDC: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
    },
}


const NetworkList = [

    {
        name: ChainName.MATIC,
        symbol: ChainSymbol.MATIC,
        token: "MATIC",
        icon: Matic,
        type: "EVM",
        chainId: ChainIdList.MATIC,
        rpc: RPCList.MATIC,
        explorer: ExplorerList.MATIC,
        tokenList: [
            {
                tokenName: "Polygon",
                tokenSymbol: "MATIC",
                tokenDecimal: 18,
                tokenAddress: ZERO_ADDRESS,
                icon: Matic,
                isNative: true,
            },
            {
                tokenName: "USDT",
                tokenSymbol: "USDT",
                tokenDecimal: 6,
                tokenAddress: TokenAddressList.MATIC.USDT,
                icon: USDT,
                isNative: false,
            },
            {
                tokenName: "USDC",
                tokenSymbol: "USDC",
                tokenDecimal: 6,
                tokenAddress: TokenAddressList.MATIC.USDC,
                icon: USDC,
                isNative: false,
            },
        ],
    },
    {
        name: ChainName.BSC,
        symbol: ChainSymbol.BSC,
        token: "BNB",
        icon: BNB,
        type: "EVM",
        chainId: ChainIdList.BSC,
        rpc: RPCList.BSC,
        explorer: ExplorerList.BSC,
        tokenList: [
            {
                tokenName: "Binance",
                tokenSymbol: "BNB",
                tokenDecimal: 18,
                tokenAddress: ZERO_ADDRESS,
                icon: BNB,
                isNative: true,
            },
            {
                tokenName: "USDT",
                tokenSymbol: "USDT",
                tokenDecimal: 18,
                tokenAddress: TokenAddressList.BSC.USDT,
                icon: USDT,
                isNative: false,
            },
            {
                tokenName: "USDC",
                tokenSymbol: "USDC",
                tokenDecimal: 18,
                tokenAddress: TokenAddressList.BSC.USDC,
                icon: USDC,
                isNative: false,
            },
        ],
    },
    // {
    //     name: ChainName.ETH,
    //     symbol: ChainSymbol.ETH,
    //     token: "ETH",
    //     icon: Ethereum,
    //     chainId: ChainIdList.ETH,
    //     rpc: RPCList.ETH,
    //     explorer: ExplorerList.ETH,
    //     type: "EVM",
    //     tokenList: [
    //         {
    //             tokenName: "Ether",
    //             tokenSymbol: "ETH",
    //             tokenDecimal: 18,
    //             tokenAddress: ZERO_ADDRESS,
    //             icon: Ethereum,
    //             isNative: true,
    //         },
    //         {
    //             tokenName: "USDT",
    //             tokenSymbol: "USDT",
    //             tokenDecimal: 6,
    //             tokenAddress: TokenAddressList.ETH.USDT,
    //             icon: USDT,
    //             isNative: false,
    //         },
    //         {
    //             tokenName: "USDC",
    //             tokenSymbol: "USDC",
    //             tokenDecimal: 6,
    //             tokenAddress: TokenAddressList.ETH.USDC,
    //             icon: USDC,
    //             isNative: false,
    //         },
    //     ],
    // },
    {
        name: ChainName.AVAX,
        symbol: ChainSymbol.AVAX,
        token: "AVAX",
        icon: Avalanche,
        type: "EVM",
        chainId: ChainIdList.AVAX,
        rpc: RPCList.AVAX,
        explorer: ExplorerList.AVAX,
        tokenList: [
            {
                tokenName: "Avalanche",
                tokenSymbol: "AVAX",
                tokenDecimal: 18,
                tokenAddress: ZERO_ADDRESS,
                icon: Avalanche,
                isNative: true,
            },
            {
                tokenName: "USDT",
                tokenSymbol: "USDT",
                tokenDecimal: 6,
                tokenAddress: TokenAddressList.AVAX.USDT,
                icon: USDT,
                isNative: false,
            },
            {
                tokenName: "USDC",
                tokenSymbol: "USDC",
                tokenDecimal: 6,
                tokenAddress: TokenAddressList.AVAX.USDC,
                icon: USDC,
                isNative: false,
            },
        ],
    },
    // {
    //     name: ChainName.OP,
    //     symbol: ChainSymbol.OP,
    //     token: "OP",
    //     icon: Optimism,
    //     type: "EVM",
    //     chainId: ChainIdList.OP,
    //     rpc: RPCList.OP,
    //     explorer: ExplorerList.OP,
    //     tokenList: [
    //         {
    //             tokenName: "OPTIMISM",
    //             tokenSymbol: "ETH",
    //             tokenDecimal: 18,
    //             tokenAddress: ZERO_ADDRESS,
    //             icon: Ethereum,
    //             isNative: true,
    //         },
    //         {
    //             tokenName: "USDT",
    //             tokenSymbol: "USDT",
    //             tokenDecimal: 6,
    //             tokenAddress: TokenAddressList.OP.USDT,
    //             icon: USDT,
    //             isNative: false,
    //         },
    //         {
    //             tokenName: "USDC",
    //             tokenSymbol: "USDC",
    //             tokenDecimal: 6,
    //             tokenAddress: TokenAddressList.OP.USDC,
    //             icon: USDC,
    //             isNative: false,
    //         },
    //     ],
    // },
    // {
    //     name: ChainName.ARB,
    //     symbol: ChainSymbol.ARB,
    //     token: "ARB",
    //     icon: Arbitrum,
    //     type: "EVM",
    //     chainId: ChainIdList.ARB,
    //     rpc: RPCList.ARB,
    //     explorer: ExplorerList.ARB,
    //     tokenList: [
    //         {
    //             tokenName: "Arbitrum",
    //             tokenSymbol: "ETH",
    //             tokenDecimal: 18,
    //             tokenAddress: ZERO_ADDRESS,
    //             icon: Ethereum,
    //             isNative: true,
    //         },
    //         {
    //             tokenName: "USDT",
    //             tokenSymbol: "USDT",
    //             tokenDecimal: 6,
    //             tokenAddress: TokenAddressList.ARB.USDT,
    //             icon: USDT,
    //             isNative: false,
    //         },
    //         {
    //             tokenName: "USDC",
    //             tokenSymbol: "USDC",
    //             tokenDecimal: 6,
    //             tokenAddress: TokenAddressList.ARB.USDC,
    //             icon: USDC,
    //             isNative: false,
    //         },
    //     ],
    // }
];

export {
    NetworkList,
    ChainIdList,
    ExplorerList,
    RPCList,
    TokenAddressList,
    ChainName,
    ChainSymbol,
    ChainTokenSymbol,
    TokenIcon,
    NetworkByChaindId
}