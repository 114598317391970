import React from 'react'
import Imgs from '../assets/images/aanand-logo.svg'

import IconDashboard from '../assets/images/dashboard-icon.svg'
import IconBuyANDToken from '../assets/images/buy-and-token-icon.svg'
import IconTransaction from '../assets/images/transaction-icon.svg'
import { emphasisAddress } from '../utils/helper'

import IconANDIcon from '../assets/images/and-logo-icon.svg'
import IconMetaMask from '../assets/images/metamask.svg'
import IconLogout from '../assets/images/logout.svg'
import IconArrowRight from '../assets/images/arrow-right-gray.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import "../style.css"

const Sidebar = ({ handleConnect, walletAddress }) => {
    const location = useLocation(); // Access the current URL path
    const navigate = useNavigate()

    // Helper function to determine if the current path matches a specific route
    const getActiveClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };


    return (
        <div className='sidebar-menu'>
            <div>
                <img onClick={() => { navigate("/") }} src={Imgs} alt='menu-icon' />
                <ul>
                    <li>
                        <Link to='/' className={getActiveClass('/home')}>
                            <img src={IconDashboard} alt="home" /> Home
                        </Link>
                    </li>
                    <li>
                        <Link to='/dashboard' className={getActiveClass('/dashboard')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="sizesmicon">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z" />
                            </svg>
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to='/buy-and' className={getActiveClass('/buy-and')}>
                            <img src={IconBuyANDToken} alt="buy-icon" /> Buy AND Token
                        </Link>
                    </li>
                    <li>
                        <Link to='/transactions' className={getActiveClass('/transactions')}>
                            <img src={IconTransaction} alt="transaction-icon" /> Transaction
                        </Link>
                    </li>
                </ul>
            </div>
            <div className='connect-sidebar'>
                <h4>Connect Wallet</h4>
                <div className='connect-button-side and-connect'>
                    <div className='content-connect'>
                        <div className='icon-connect'><img src={IconANDIcon} alt="and-logo" /></div>
                        <div className='connect-name'>
                            <h5>AND
                                {/* <div className='smalltext-p'>coming soon</div> */}
                            </h5>
                        </div>
                    </div>
                    <div className='right-icon-connect'>
                        <button className='btn-logout'><img src={IconArrowRight} alt="right-icon" /></button>
                    </div>
                </div>
                {walletAddress && <div className='connect-button-side'>
                    <div className='content-connect'>
                        <div className='icon-connect'><img src={IconMetaMask} alt="metamask-icon" /></div>
                        <div className='connect-name'>
                            <h5>Connected</h5>
                            <p>{emphasisAddress(walletAddress)}</p>
                        </div>
                    </div>
                    <div className='right-icon-connect'>
                        <button onClick={handleConnect} className='btn-logout'><img src={IconLogout} alt="logout-logo" /></button>
                    </div>
                    <div className='active-green'></div>
                </div>}
            </div>
        </div>
    )
}

export default Sidebar